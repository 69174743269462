"use strict";

class SearchableField {
  constructor(attributes) {
    this.loadDataFromAttributes(attributes);
  }

  loadDataFromAttributes(attributes) {
    this.fieldName = attributes["field"];
    this.displayName = attributes["displayName"];
    this.description = attributes["description"];
    this.dataType = attributes["dataType"];
    this.resultDataType = attributes["resultDataType"];
    this.resultKey = attributes["resultKey"];
    this.comparisonMethods = attributes["comparisonMethods"] || [];
    this.listOptions = attributes["listOptions"];
    this.fieldIsUsedToSearchForEntityType =
      attributes["fieldIsUsedToSearchForEntityType"];
    this.modelSubSearchFilters = attributes["modelSubSearchFilters"];
    this.objectLinkSearchableFieldWithId =
      attributes["objectLinkSearchableFieldWithId"];
    this.isFieldSearchable = attributes["isFieldSearchable"];
    this.isFieldDisplayedInResults = attributes["isFieldDisplayedInResults"];
    this.isFieldDisplayedInResultsByDefault =
      attributes["isFieldDisplayedInResultsByDefault"];
    this.isFieldSortable = attributes["isFieldSortable"];
    this.isFieldSearchableByStaffMembersOnly =
      attributes["isFieldSearchableByStaffMembersOnly"];
  }

  static GET_DATA_TYPES() {
    return {
      TEXT: "text",
      NUMBER: "number",
      BOOLEAN: "boolean",
      MONTH: "month",
      DATE: "date",
      DATE_TIME: "dateTime",
      MULTIPLE_SELECT_LIST_PAIR: "multipleSelectListPair",
      SINGLE_SELECT_LIST: "singleSelectList",
      MULTIPLE_SELECT_LIST: "multipleSelectList",
      SINGLE_SELECT_ENTITY_SEARCH: "singleSelectEntitySearch",
      MULTIPLE_SELECT_ENTITY_SEARCH: "multipleSelectEntitySearch",
    };
  }

  get DATA_TYPES() {
    if (!this["_dataTypes"]) this._dataTypes = SearchableField.GET_DATA_TYPES();

    return this._dataTypes;
  }

  get isDataTypeText() {
    return this.dataType == this.DATA_TYPES.TEXT;
  }
  get isDataTypeNumber() {
    return this.dataType == this.DATA_TYPES.NUMBER;
  }
  get isDataTypeBoolean() {
    return this.dataType == this.DATA_TYPES.BOOLEAN;
  }
  get isDataTypeMonth() {
    return this.dataType == this.DATA_TYPES.MONTH;
  }
  get isDataTypeDate() {
    return this.dataType == this.DATA_TYPES.DATE;
  }
  get isDataTypeDateTime() {
    return this.dataType == this.DATA_TYPES.DATE_TIME;
  }
  get isDataTypeMultipleSelectListPair() {
    return this.dataType == this.DATA_TYPES.MULTIPLE_SELECT_LIST_PAIR;
  }
  get isDataTypeSingleSelectList() {
    return this.dataType == this.DATA_TYPES.SINGLE_SELECT_LIST;
  }
  get isDataTypeMultipleSelectList() {
    return this.dataType == this.DATA_TYPES.MULTIPLE_SELECT_LIST;
  }
  get isDataTypeSingleSelectEntitySearch() {
    return this.dataType == this.DATA_TYPES.SINGLE_SELECT_ENTITY_SEARCH;
  }
  get isDataTypeMultipleSelectEntitySearch() {
    return this.dataType == this.DATA_TYPES.MULTIPLE_SELECT_ENTITY_SEARCH;
  }

  get isDataTypeMultipleSelect() {
    return (
      this.isDataTypeMultipleSelectList ||
      this.isDataTypeMultipleSelectEntitySearch ||
      this.isDataTypeMultipleSelectListPair
    );
  }
  get isDataTypeAList() {
    return (
      this.isDataTypeSingleSelectList ||
      this.isDataTypeMultipleSelectList ||
      this.isDataTypeMultipleSelectListPair
    );
  }
  get isDataTypeAnEntitySearch() {
    return (
      this.isDataTypeSingleSelectEntitySearch ||
      this.isDataTypeMultipleSelectEntitySearch
    );
  }
}

export default SearchableField;
