"use strict";

import $ from "jquery";

import ApiRequest from "@/shared/lib/client-sdk/api-request";

import SearchResult from "./search-result";
import SearchFilter from "./search-filter";
import SearchableField from "./searchable-field";

import AccountType from "../models/account-type";
import Account from "../models/account";
import AccountUser from "../models/account-user";
import TaxAdjustmentReason from "../models/tax-adjustment-reason";
import AssetTrackingLedger from "../models/asset-tracking-ledger";
import AssetTrackingLedgerEntry from "../models/asset-tracking-ledger-entry";
import Agreement from "../models/agreement";
import AgreementAmendmentVersion from "../models/agreement-amendment-version";
import AgreementVersion from "../models/agreement-version";
import Anything from "../models/anything";
import AnnuityType from "../models/annuity-type";
import BankTransaction from "../models/bank-transaction";
import BankTransactionFile from "../models/bank-transaction-file";
import BankTransactionType from "../models/bank-transaction-type";
import Campaign from "../models/campaign";
import CompanyType from "../models/company-type";
import CorporateBankAccount from "../models/corporate-bank-account";
import Country from "../models/country";
import Deposit from "../models/deposit";
import DepositLink from "../models/deposit-link";
import DocumentTemplate from "../models/document-template";
import MailDeliveryMethod from "../models/mail-delivery-method";
import DistributionReason from "../models/distribution-reason";
import DistributionType from "../models/distribution-type";
import EntityChangeLog from "../models/entity-change-log";
import EntityFlag from "../models/entity-flag";
import EntityNote from "../models/entity-note";
import EntityDocument from "../models/entity-document";
import EnumDisplay from "../models/enum-display";
import FeeType from "../models/fee-type";
import FinCenFile from "../models/fincen-file";
import InboundParsedFilePdfExtractedImage from "../models/inbound-parsed-file-pdf-extracted-image";
import InboundParsedFilePdfPageLineBlock from "../models/inbound-parsed-file-pdf-page-line-block";
import InboundRawFile from "../models/inbound-raw-file";
import InboundParsedOrderFile from "../models/inbound-parsed-order-file";
import InboundParsedJohnHancockFile from "../models/inbound-parsed-john-hancock-file";
import Institution from "../models/institution";
import Invoice from "../models/invoice";
import InvoiceLine from "../models/invoice-line";
import MasterAccountHeldFee from "../models/master-account-held-fee";
import NationalRegistryAccount from "../models/national-registry-account";
import NationalRegistryParticipant from "../models/national-registry-participant";
import NotificationType from "../models/notification-type";
import OrderLineDetailDistributionService from "../models/order-line-detail-distribution-service";
import OrderLine from "../models/order-line";
import OrderLineTax from "../models/order-line-tax";
import OrderLineFee from "../models/order-line-fee";
import OrderLineAssociation from "../models/order-line-association";
import OrderLineDetailVendorDistributionService from "../models/order-line-detail-vendor-distribution-service";
import Order from "../models/order";
import Participant from "../models/participant";
import RecurringBatch from "../models/recurring-batch";
import RecurringBatchOrder from "../models/recurring-batch-order";
import ReportType from "../models/report-type";
import Payment from "../models/payment";
import PaymentMethod from "../models/payment-method";
import Permission from "../models/permission";
import PlanCustodian from "../models/plan-custodian";
import PlanTrustee from "../models/plan-trustee";
import PlanType from "../models/plan-type";
import Plan from "../models/plan";
import Prospect from "../models/prospect";
import Role from "../models/role";
import Service from "../models/service";
import State from "../models/state";
import TaxFormCode from "../models/tax-form-code";
import TaxRefund from "../models/tax-refund";
import TaxReportingChangeLog from "../models/tax-reporting-change-log";
import TaxReportingFile from "../models/tax-reporting-file";
import User from "../models/user";
import PaymentInstruction from "../models/payment-instruction";
import PaymentBatch from "../models/payment-batch";
import PaymentBatchPaymentInstruction from "../models/payment-batch-payment-instruction";
import DocumentBatch from "../models/document-batch";
import DepositBatch from "../models/deposit-batch";
import DepositBatchOrderLine from "../models/deposit-batch-order-line";
import OrderLineDocument from "../models/order-line-document";
import Message from "../models/message";
import Notice from "../models/notice";
import VendorDistributionReason from "../models/vendor-distribution-reason";

class SearchRequestHandler {
  static allResultsPerPage = -1;

  constructor(modelClassName) {
    this.setModelClassName(modelClassName);

    this.generalFilter = "";
    this.searchableFields = []; // Array of SearchableField objects
    this.defaultDisplayFieldsOverride = []; // Array of strings, should contain the field names you to display by default, eg: ["FriendlyId", "PlanDisplayName"], this will replace the default display fields returned by the API search request.

    this.searchFilters = []; // Array of SearchFilter objects, upon reset, this gets cleared to empty.  This is typically added to from the UI.
    this.defaultSearchFilters = []; // An array of default filters that are only added to the search query on the initial search request, and can be changed later.  These search filters are used again when "reset() is called".
    this.privateSearchFilters = []; // Array of literal filters, eg: "search-FieldName=equals(43)", these are when you don't want them to show up in the UI, or be changeable.  They are added literally to every search.

    this._forceQueryString = null; // If you want to force the search query string entirely, you can set this.  All previous search filters are ignored for 1 search request, then this is cleared out.
    this.lastForcedQueryString = null;

    this.addDefaultSearchFilters = true; // Defaults to true, get's set to false after the first search.  Is set back to true when "reset" is called.

    this.page = 1;
    this.resultsPerPage = 25;
    this.sortBy = null;
    this.sortDirection = null;
    this.includeDeleted = false;

    this.returnObjects = false;
    this.returnAbbreviatedResponses = false;

    this._isBusy = false;
    this._requestUrl = null;

    this.searchResult = new SearchResult();
    this._apiRequest = null;

    this.hasSearchedAtLeastOnce = false;

    this.onSearchStart = null;
    this.onSearchComplete = null;
    this.tableComponentCallback = null;
    this.adminSearchCallback = null;

    this.fetchingFullCount = false;
  }

  setModelClassName(modelClassName) {
    // Any model that can be searched, must be registered in this list in order to be searched.
    this.searchableModels = {
      AccountType: AccountType,
      Account: Account,
      AccountUser: AccountUser,
      TaxAdjustmentReason: TaxAdjustmentReason,
      AdvisoryFirm: Account,
      AssetTrackingLedger: AssetTrackingLedger,
      AssetTrackingLedgerEntry: AssetTrackingLedgerEntry,
      MasterAccount: Account,
      Subaccount: Account,
      Agreement: Agreement,
      AgreementAmendmentVersion: AgreementAmendmentVersion,
      AgreementVersion: AgreementVersion,
      Anything: Anything,
      AnnuityType: AnnuityType,
      BankTransaction: BankTransaction,
      BankTransactionFile: BankTransactionFile,
      BankTransactionType: BankTransactionType,
      Campaign: Campaign,
      CompanyType: CompanyType,
      CorporateBankAccount: CorporateBankAccount,
      Country: Country,
      Deposit: Deposit,
      DepositLink: DepositLink,
      DocumentTemplate: DocumentTemplate,
      MailDeliveryMethod: MailDeliveryMethod,
      DistributionReason: DistributionReason,
      DistributionType: DistributionType,
      EntityChangeLog: EntityChangeLog,
      EntityFlag: EntityFlag,
      EntityNote: EntityNote,
      EntityDocument: EntityDocument,
      EnumDisplay: EnumDisplay,
      FeeType: FeeType,
      FinCenFile: FinCenFile,
      InboundParsedFilePdfExtractedImage: InboundParsedFilePdfExtractedImage,
      InboundParsedFilePdfPageLineBlock: InboundParsedFilePdfPageLineBlock,
      InboundRawFile: InboundRawFile,
      InboundParsedOrderFile: InboundParsedOrderFile,
      InboundParsedJohnHancockFile: InboundParsedJohnHancockFile,
      Institution: Institution,
      Invoice: Invoice,
      InvoiceLine: InvoiceLine,
      MasterAccountHeldFee: MasterAccountHeldFee,
      Message: Message,
      NationalRegistryAccount: NationalRegistryAccount,
      NationalRegistryParticipant: NationalRegistryParticipant,
      NotificationType: NotificationType,
      OrderLineDetailDistributionService: OrderLineDetailDistributionService,
      OrderLineDetailVendorDistributionService:
        OrderLineDetailVendorDistributionService,
      OrderLine: OrderLine,
      OrderLineTax: OrderLineTax,
      OrderLineFee: OrderLineFee,
      OrderLineAssociation: OrderLineAssociation,
      Order: Order,
      Participant: Participant,
      Payment: Payment,
      PaymentMethod: PaymentMethod,
      Permission: Permission,
      PlanCustodian: PlanCustodian,
      PlanTrustee: PlanTrustee,
      PlanType: PlanType,
      Plan: Plan,
      Prospect: Prospect,
      Role: Role,
      RecurringBatch: RecurringBatch,
      RecurringBatchOrder: RecurringBatchOrder,
      ReportType: ReportType,
      Service: Service,
      State: State,
      TaxFormCode: TaxFormCode,
      TaxRefund: TaxRefund,
      TaxReportingChangeLog: TaxReportingChangeLog,
      TaxReportingFile: TaxReportingFile,
      User: User,
      PaymentInstruction: PaymentInstruction,
      PaymentBatch: PaymentBatch,
      PaymentBatchPaymentInstruction: PaymentBatchPaymentInstruction,
      DocumentBatch: DocumentBatch,
      DepositBatch: DepositBatch,
      DepositBatchOrderLine: DepositBatchOrderLine,
      OrderLineDocument: OrderLineDocument,
      Notice: Notice,
      VendorDistributionReason: VendorDistributionReason,
    };

    this.modelClassName = modelClassName;
    this.modelClass = null;
    if (typeof this.searchableModels[modelClassName] == "undefined") {
      console.error(
        "Please register class name `" +
          modelClassName +
          "` in SearchRequestHandler."
      );
    } else {
      this.modelClass = this.searchableModels[modelClassName];
    }
  }

  get isBusy() {
    return this._isBusy;
  }

  getSearchableFieldByName(fieldName) {
    for (var i = 0; i < this.searchableFields.length; i++) {
      if (
        this.searchableFields[i].fieldName.toLowerCase() ==
        fieldName.toLowerCase()
      )
        return this.searchableFields[i];
    }
  }

  forceQueryString(queryString) {
    this._forceQueryString = queryString;
  }

  _getQueryString() {
    if (this._forceQueryString) {
      return this._forceQueryString;
    }

    var queryItems = [];
    var addUniqueQueryItem = (value) => {
      if (!queryItems.includes(value)) {
        queryItems.push(value);
      }
    };

    if (this.generalFilter && this.generalFilter.length > 0) {
      addUniqueQueryItem("query=" + encodeURIComponent(this.generalFilter));
    }

    if (this.privateSearchFilters) {
      for (var i = 0; i < this.privateSearchFilters.length; i++) {
        addUniqueQueryItem(this.privateSearchFilters[i]);
      }
    }

    if (this.addDefaultSearchFilters) {
      for (let i = 0; i < this.defaultSearchFilters.length; i++) {
        addUniqueQueryItem(this.defaultSearchFilters[i]);
      }
    }

    for (let i = 0; i < this.searchFilters.length; i++) {
      addUniqueQueryItem(this.searchFilters[i]._getQueryString());
    }

    addUniqueQueryItem("page=" + parseInt(this.page));
    addUniqueQueryItem("resultsPerPage=" + parseInt(this.resultsPerPage));

    if (this.sortBy && this.sortBy.length > 1) {
      addUniqueQueryItem("sortBy=" + encodeURIComponent(this.sortBy));

      if (this.sortDirection) {
        addUniqueQueryItem(
          "sortDirection=" + encodeURIComponent(this.sortDirection)
        );
      }
    }

    if (this.includeDeleted) {
      addUniqueQueryItem("includeDeleted=1");
    }

    if (this.returnObjects) {
      addUniqueQueryItem("objects=1");

      if (this.returnAbbreviatedResponses) {
        addUniqueQueryItem("abbreviated=1");
      }
    }

    return queryItems.join("&");
  }

  setRequestUrl(newRequestUrl) {
    this._requestUrl = newRequestUrl;
  }

  get requestUrl() {
    return this._requestUrl || this.modelClass.getApiPathForSearch();
  }

  get requestUrlWithQueryString() {
    return this.requestUrl + "?" + this._getQueryString();
  }

  loadSearchableFields(callback) {
    const loadSearchResults = false;
    this.search(callback, loadSearchResults);
  }

  cancel() {
    if (this._isBusy || this.fetchingFullCount) {
      this._apiRequest.cancel();
    }
  }

  search(callback, loadSearchResults = true) {
    if (!this.modelClass) {
      console.error(
        "Cannot search without a valid model class on the search handler."
      );
      return;
    }

    if (this._isBusy) {
      console.error("Search request handler is busy. Cannot search.");
      return;
    }

    if (this.fetchingFullCount) {
      this._apiRequest.cancel();
    }

    this._isBusy = true;
    this.fetchingFullCount = false;

    if ($.isFunction(this.onSearchStart)) this.onSearchStart();

    this._apiRequest = ApiRequest.send(
      "GET",
      this.requestUrlWithQueryString
    ).onComplete(
      function (response) {
        this.hasSearchedAtLeastOnce = true;
        this.lastForcedQueryString = this._forceQueryString; // This will always be 1 step behind so we can check the last used forced query string
        this._forceQueryString = null; // Clear out any forced query string
        this._isBusy = false;

        if (response.successful) {
          // Populate the search request with all the possible searchable fields
          this._loadSeachableFieldsFromResponse(response);
          this._loadFiltersFromResponse(response);

          // Replace default display fields if defaultDisplayFieldsOverride array has items
          if (this.defaultDisplayFieldsOverride.length > 0) {
            // set each field to false
            this.searchableFields.forEach((field) => {
              if (this.defaultDisplayFieldsOverride.includes(field.fieldName)) {
                field.isFieldDisplayedInResultsByDefault = true;
              } else {
                field.isFieldDisplayedInResultsByDefault = false;
              }
            });
          }

          this.addDefaultSearchFilters = false;

          // We don't load search results if we are simply trying to get the searchable fields list.
          // In this case loadSearchableFields() passes "false" to loadSearchResults
          if (loadSearchResults) {
            this.searchResult.loadSearchResponse(
              response.data,
              this.modelClass
            );

            if ($.isFunction(callback)) callback(this.searchResult);

            if ($.isFunction(this.onSearchComplete))
              this.onSearchComplete(this.searchResult);
            if ($.isFunction(this.tableComponentCallback))
              this.tableComponentCallback(this.searchResult);
            if ($.isFunction(this.adminSearchCallback))
              this.adminSearchCallback(this.searchResult);

            if (!this.isUnFilteredSearch() && this.page == 1) {
              this.fetchFullCount();
            }
          }
        } else {
          console.error("Error searching.");

          if ($.isFunction(callback)) callback(null);
        }
      }.bind(this)
    );
  }

  queueExport(exportRequest, callback) {
    var exportColumns = "";
    if (exportRequest.customColumns && exportRequest.customColumns.length > 0) {
      exportColumns = "&columns=" + exportRequest.customColumns;
    }
    var requestUrl =
      this.requestUrl +
      "?" +
      this._getQueryString() +
      exportColumns +
      "&export=1";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful && response.data.search) {
          if ($.isFunction(callback))
            callback(true, response.data.search.exportDownloadUri);
        } else {
          if ($.isFunction(callback)) callback(false);
        }
      }.bind(this)
    );
  }

  isUnFilteredSearch() {
    return (
      (this.generalFilter == null || this.generalFilter == "") &&
      (this.searchFilters == null || this.searchFilters.length == 0)
    );
  }

  fetchFullCount() {
    this.fetchingFullCount = true;

    this._apiRequest = ApiRequest.send(
      "GET",
      this.requestUrlWithQueryString + "&returnRecordCountsOnly=1"
    ).onComplete((response) => {
      if (response.successful) {
        const collectionData = response.data.collection;
        this.searchResult.page = collectionData
          ? collectionData.page
          : this.searchResult.page;
        this.searchResult.resultsPerPage = collectionData
          ? collectionData.resultsPerPage
          : this.searchResult.resultsPerPage;
        this.searchResult.totalPages = collectionData
          ? collectionData.totalPages
          : this.searchResult.totalPages;
        this.searchResult.totalResults = collectionData
          ? collectionData.totalResults
          : this.searchResult.totalResults;
      }
    });

    this.fetchingFullCount = false;
  }

  _loadSeachableFieldsFromResponse(response) {
    if (!response.data || !response.data.search || !response.data.search.fields)
      return;

    const fields = response.data.search.fields;
    for (var i = 0; i < fields.length; i++) {
      const fieldData = fields[i];

      const existingField = this.getSearchableFieldByName(fieldData.field);

      if (existingField) {
        existingField.loadDataFromAttributes(fieldData);
      } else {
        const searchableField = new SearchableField(fieldData);
        this.searchableFields.push(searchableField);
      }
    }
  }

  _loadFiltersFromResponse(response) {
    if (!response.data || !response.data.search) return;

    this.generalFilter = response.data.search.query;
    this.includeDeleted = response.data.search.includeDeleted;
    this.returnAbbreviatedResponses = response.data.search.abbreviated;

    this.searchFilters = [];
    const filters = response.data.search.filters;
    for (var i = 0; i < filters.length; i++) {
      var filter = filters[i];
      // Only add the filter to the search filters array, if it's not set as a literal filter.
      var literalFilterString =
        "search-" +
        filter.field +
        "=" +
        filter.comparisonMethod +
        "(" +
        filter.filterValue +
        ")";
      if (!this.privateSearchFilters.includes(literalFilterString)) {
        this.addFilter(
          filter.field,
          filter.comparisonMethod,
          filter.filterValue
        );
      }
    }
  }

  addEmptyFilter() {
    var firstSearchableField = this.searchableFields.find(
      (x) => x.isFieldSearchable
    );

    const filter = new SearchFilter(
      firstSearchableField,
      SearchFilter.COMPARISON_METHODS().EQUALS,
      null
    );
    this.searchFilters.push(filter);
    return filter;
  }

  removeAllFiltersWithName(fieldName) {
    this._removePrivateFilter(fieldName);
    this._removeDefaultFilter(fieldName);

    var filtersToRemove = [];
    for (let j = 0; j < this.searchFilters.length; j++) {
      var filter = this.searchFilters[j];

      if (filter._getQueryString().startsWith("search-" + fieldName + "=")) {
        filtersToRemove.push(filter);
      }
    }

    for (let j = 0; j < filtersToRemove.length; j++) {
      var index = this.searchFilters.indexOf(filtersToRemove[j]);
      if (index >= 0) {
        this.searchFilters.splice(index, 1);
      }
    }
  }

  addPrivateFilter(fieldName, comparisonMethod, value) {
    return this._addFilterToArray(
      "privateSearchFilters",
      fieldName,
      comparisonMethod,
      value
    );
  }

  _removePrivateFilter(fieldName) {
    return this._removeFilterFromArray("privateSearchFilters", fieldName);
  }

  clearPrivateFilters() {
    for (var i = 0; i < this.privateSearchFilters.length; i++) {
      // If any of the items are in the search filters array, remove it
      this._removeFilterFromSearchFiltersIfExactMatch(
        this.privateSearchFilters[i]
      );
    }

    this.privateSearchFilters = [];
  }

  addDefaultFilter(fieldName, comparisonMethod, value) {
    return this._addFilterToArray(
      "defaultSearchFilters",
      fieldName,
      comparisonMethod,
      value
    );
  }

  _removeDefaultFilter(fieldName) {
    return this._removeFilterFromArray("defaultSearchFilters", fieldName);
  }

  clearDefaultFilters() {
    for (var i = 0; i < this.defaultSearchFilters.length; i++) {
      // If any of the items are in the search filters array, remove it
      this._removeFilterFromSearchFiltersIfExactMatch(
        this.defaultSearchFilters[i]
      );
    }

    this.defaultSearchFilters = [];
  }

  _addFilterToArray(arrayName, fieldName, comparisonMethod, value) {
    if (typeof value == "undefined" || value == null) value = "";

    if ($.isArray(value)) {
      value = value.map((x) => encodeURIComponent(x));
      value = "[" + value.join(",") + "]";
    } else {
      value = encodeURIComponent(value);
    }

    var filterQueryString =
      "search-" + fieldName + "=" + comparisonMethod + "(" + value + ")";

    var filter = this._getFilterFromArray(arrayName, fieldName);
    if (filter) {
      this[arrayName][this[arrayName].indexOf(filter)] = filterQueryString;
    } else {
      this[arrayName].push(filterQueryString);
    }
  }

  _removeFilterFromArray(arrayName, fieldName) {
    var filter = this._getFilterFromArray(arrayName, fieldName);

    if (filter) {
      // If any of the items are in the search filters array, remove it
      this._removeFilterFromSearchFiltersIfExactMatch(filter);

      this[arrayName].splice(this[arrayName].indexOf(filter), 1);
    }
  }

  _getFilterFromArray(arrayName, fieldName) {
    for (var i = 0; i < this[arrayName].length; i++) {
      var filter = this[arrayName][i];
      if (filter.startsWith("search-" + fieldName + "=")) {
        return filter;
      }
    }
  }

  _removeFilterFromSearchFiltersIfExactMatch(filterQueryString) {
    var filtersToRemove = [];
    for (let j = 0; j < this.searchFilters.length; j++) {
      var filter = this.searchFilters[j];

      if (filter._getQueryString() == filterQueryString) {
        filtersToRemove.push(filter);
      }
    }

    for (let j = 0; j < filtersToRemove.length; j++) {
      var index = this.searchFilters.indexOf(filtersToRemove[j]);
      if (index >= 0) {
        this.searchFilters.splice(index, 1);
      }
    }
  }

  _removeAllFiltersThatAreNotDefaultFilters() {
    var filtersToRemove = [];
    for (let j = 0; j < this.searchFilters.length; j++) {
      var filter = this.searchFilters[j];
      var query = filter._getQueryString();

      if (!this.defaultSearchFilters.includes(query)) {
        filtersToRemove.push(filter);
      }
    }

    for (let j = 0; j < filtersToRemove.length; j++) {
      var index = this.searchFilters.indexOf(filtersToRemove[j]);
      if (index >= 0) {
        this.searchFilters.splice(index, 1);
      }
    }
  }

  addFilter(searchableFieldOrFieldName, comparisonMethod, value) {
    var searchableField = searchableFieldOrFieldName;
    if (typeof searchableFieldOrFieldName == "string") {
      searchableField = this.getSearchableFieldByName(
        searchableFieldOrFieldName
      );
    }

    const filter = new SearchFilter(searchableField, comparisonMethod, value);
    this.searchFilters.push(filter);
    return filter;
  }

  getFirstFilterWithName(fieldName) {
    for (var j = 0; j < this.searchFilters.length; j++) {
      var filter = this.searchFilters[j];
      if (
        filter.searchableField.fieldName.toLowerCase() ==
        fieldName.toLowerCase()
      ) {
        return filter;
      }
    }
  }

  clearFilters() {
    this.generalFilter = "";
    this.searchFilters = [];
    this._forceQueryString = null;
  }

  hasSearchResults() {
    return this.searchResult.totalResults > 0;
  }

  hasNoSearchResults() {
    return this.searchResult.totalResults === 0;
  }

  reset() {
    this.generalFilter = "";
    this._removeAllFiltersThatAreNotDefaultFilters();
    this.page = 1;
    this.sortBy = null;
    this.sortDirection = null;
    this.includeDeleted = false;
    this.addDefaultSearchFilters = true;
    this.search();
  }

  clone() {
    var newSearchRequestHandler = new SearchRequestHandler(this.modelClassName);

    newSearchRequestHandler.generalFilter = this.generalFilter;
    newSearchRequestHandler.searchFilters = [];
    newSearchRequestHandler.privateSearchFilters = [];
    newSearchRequestHandler.searchableFields = this.searchableFields.slice(); // clone the array

    newSearchRequestHandler.page = this.page;
    newSearchRequestHandler.resultsPerPage = this.resultsPerPage;
    newSearchRequestHandler.sortBy = this.sortBy;
    newSearchRequestHandler.sortDirection = this.sortDirection;
    newSearchRequestHandler.includeDeleted = this.includeDeleted;
    newSearchRequestHandler.hasSearchedAtLeastOnce =
      this.hasSearchedAtLeastOnce;
    newSearchRequestHandler.quickSearch = this.quickSearch;
    newSearchRequestHandler._forceQueryString = this._forceQueryString;
    newSearchRequestHandler.addDefaultSearchFilters =
      this.addDefaultSearchFilters;

    newSearchRequestHandler.returnAbbreviatedResponses =
      this.returnAbbreviatedResponses;

    newSearchRequestHandler._requestUrl = this._requestUrl;

    for (let i = 0; i < this.searchFilters.length; i++) {
      newSearchRequestHandler.searchFilters.push(this.searchFilters[i].clone());
    }

    for (let i = 0; i < this.privateSearchFilters.length; i++) {
      newSearchRequestHandler.privateSearchFilters.push(
        this.privateSearchFilters[i]
      );
    }

    for (let i = 0; i < this.defaultSearchFilters.length; i++) {
      newSearchRequestHandler.defaultSearchFilters.push(
        this.defaultSearchFilters[i]
      );
    }

    newSearchRequestHandler.searchResult = this.searchResult.clone();

    return newSearchRequestHandler;
  }

  queueBackgroundExport(exportRequest, callback) {
    var requestEndPointUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/utils/queue-job/export-report";

    var reportBaseUrl = this.requestUrl;
    var modelClass = this.modelClassName;
    var exportColumns = "";
    if (exportRequest.customColumns && exportRequest.customColumns.length > 0) {
      exportColumns = "&columns=" + exportRequest.customColumns;
    }
    var reportQueryString =
      "?" + this._getQueryString() + exportColumns + "&export=1";

    var requestData = {
      reportTitle: exportRequest.reportTitle,
      entityType: modelClass,
      reportBaseUrl: reportBaseUrl,
      reportQueryString: reportQueryString,
    };

    ApiRequest.send("POST", requestEndPointUrl, requestData).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }
    );
  }
}

export default SearchRequestHandler;
