<template>
  <!-- Snackbar, flash text at bottom of screen -->
  <v-snackbar
    v-model="show"
    :timeout="timeout"
    :multi-line="true"
    :color="color"
    :left="left"
    :top="top"
    :bottom="bottom"
    :right="right"
  >
    {{ text }}
    <v-btn
      v-if="closeButtonText"
      :color="closeButtonColor"
      text
      @click="show = false"
    >
      {{ closeButtonText }}
    </v-btn>
  </v-snackbar>
</template>

<script>
import Singletons from "@/shared/plugins/singletons";
import $ from "jquery";

export default {
  components: {},

  data: () => ({
    show: false,
    text: null,
    timeout: null,
    color: null,
    closeButtonText: null,
    closeButtonColor: null,
    left: null,
    right: null,
    bottom: null,
    top: null,
  }),

  methods: {
    /**
     * Displays a temporary message on the screen
     *
     * options (object or text)
     *  object { text, timeout, color, closeButtonText, closeButtonColor, left, right, bottom, top }
     */
    showSnackbar: function (options) {
      // If we pass a single string, just use that as the message text
      if ($.type(options) === "string") options = { text: options };

      if (!$.isPlainObject(options)) options = {};

      // First, turn the snackbar off
      this.show = false;

      // Load in the values, default values on the right if unpassed on the next go round, so timer is reset
      this.$nextTick(() => {
        this.$nexttick;
        this.text = options["text"] || "";
        this.color = options["color"] || null;
        this.timeout = options["timeout"] || 5000;
        this.closeButtonText = options["closeButtonText"] || "CLOSE";
        this.closeButtonColor = options["closeButtonColor"] || null;
        this.left = options["left"] || null;
        this.right =
          options["right"] || options["left"] !== true ? true : false;
        this.top = options["top"] || null;
        this.bottom =
          options["bottom"] || options["left"] !== true ? true : false;
        this.show = true;
      });
    },
    hideSnackbar: function () {
      this.show = false;
    },
  },

  mounted: function () {
    Singletons.Events.$on("showSnackbar", this.showSnackbar.bind(this));
    Singletons.Events.$on("hideSnackbar", this.hideSnackbar.bind(this));
  },
};
</script>

<style lang="scss"></style>
