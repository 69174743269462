<template>
  <span
    class="social-security-number-value"
    :class="{ 'has-error': required && !isValid }"
  >
    <template v-if="isValid && canShowUnmaskedValue">
      <pc-tooltip value="Click to copy, and toggle visibility.">
        <a
          href="javascript:void(0)"
          @click.prevent="numberClicked"
          v-if="currentTaxIdType === 'employerIdentificationNumber'"
        >
          {{ currentValue | employerIdentificationNumber }}
        </a>
        <a
          href="javascript:void(0)"
          @click.prevent="numberClicked"
          v-else-if="currentTaxIdType === 'w8Ben'"
        >
          {{ currentValue }}
        </a>
        <a href="javascript:void(0)" @click.prevent="numberClicked" v-else>
          {{ currentValue | socialSecurityNumber }}
        </a>
      </pc-tooltip>
      <input
        type="text"
        v-model="unmaskedValue"
        v-if="copying"
        style="opacity: 0; width: 10px; height: 1px; position: absolute"
      />
    </template>
    <template v-else>
      {{ displayedValue }}
    </template>
  </span>
</template>

<script>
import $ from "jquery";
import Participant from "@/shared/lib/client-sdk/models/participant";

export default {
  props: {
    value: {
      type: String,
    },
    participant: {
      type: Participant,
    },
    required: {
      type: Boolean,
    },
    flagMissingData: {
      type: Boolean,
    },
    taxIdType: {
      type: String,
    },
  },
  data: function () {
    return {
      isShowing: false,
      copying: false,
    };
  },
  methods: {
    numberClicked: function () {
      if (this.canShowUnmaskedValue) this.isShowing = !this.isShowing;

      this.copying = true;

      this.$nextTick(() => {
        $(this.$el).children("input").focus();
        $(this.$el).children("input").select();
        document.execCommand("copy");
        this.copying = false;

        this.$snackbar.show(
          "'" + this.unmaskedValue + "' copied to clipboard."
        );
      });
    },
  },
  computed: {
    canShowUnmaskedValue: function () {
      if (this.$store.state.authenticatedUser.cant("viewFullTaxId"))
        return false;

      return !this.unmaskedValue?.toUpperCase().includes("X");
    },
    currentValue: function () {
      if (this.isShowing) return this.unmaskedValue;

      return this.maskedValue;
    },
    unmaskedValue: function () {
      if (this.participant && this.participant.unmaskedTaxId) {
        return this.participant.unmaskedTaxId.replace(/-/g, "").trim();
      } else if (this.value) {
        return this.value.replace(/-/g, "").trim();
      }

      return null;
    },
    maskedValue: function () {
      let unmaskedValue = this.unmaskedValue;

      if (unmaskedValue) {
        const firstChar = unmaskedValue.startsWith("9") ? "9" : "X";
        let maskedValue = `${firstChar}XXXX`;

        if (
          this.participant?.taxIdType === "w8Ben" ||
          this.taxIdType === "w8Ben"
        ) {
          return maskedValue + unmaskedValue.substring(5);
        }

        return maskedValue + unmaskedValue.substring(5, 9);
      }

      return null;
    },
    isValid: function () {
      return (
        this.unmaskedValue &&
        (this.participant?.taxIdType === "w8Ben" ||
          this.taxIdType === "w8Ben" ||
          this.unmaskedValue.length == 9) &&
        this.unmaskedValue != "000000000" &&
        this.unmaskedValue != "111111111" &&
        this.unmaskedValue != "999999999"
      );
    },
    currentTaxIdType: function () {
      return this.participant?.taxIdType ?? this.taxIdType;
    },
    displayedValue: function () {
      if (this.isValid && !this.canShowUnmaskedValue) {
        if (this.currentTaxIdType === "employerIdentificationNumber")
          return this.$options.filters.employerIdentificationNumber(
            this.maskedValue
          );

        if (this.currentTaxIdType === "w8Ben") return this.maskedValue;

        return this.$options.filters.socialSecurityNumber(this.maskedValue);
      } else if (!this.isValid && this.unmaskedValue) {
        return `${this.unmaskedValue} (invalid)`;
      } else if (this.required && this.flagMissingData) {
        return "Missing Tax ID";
      }

      return this.maskedValue;
    },
  },
};
</script>

<style lang="scss">
.social-security-number-value {
  a,
  a:link,
  a:visited {
    text-decoration: none;
    color: inherit;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: $penChecksGray;
  }

  &.has-error {
    color: $penChecksRed;
  }
}
</style>
