<template>
  <div
    :class="
      isParticipantPortalField
        ? 'password-text-field is-participant-portal-field'
        : 'password-text-field is-general-field'
    "
  >
    <div
      v-if="isParticipantPortalField && showHeader"
      class="password-text-field-header-style"
    >
      <span v-if="required" class="error-text">*</span>{{ header }}
    </div>
    <v-text-field
      :id="id"
      v-model="password"
      :rules="passwordRules"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPassword ? 'text' : 'password'"
      :label="
        isParticipantPortalField ? '' : label + (required ? '' : ' (optional)')
      "
      :placeholder="placeholder"
      :disabled="disabled"
      :color="$colors.penChecksTeal"
      :dense="dense"
      @click:append="showPassword = !showPassword"
      :validate-on-blur="false"
      :hint="showHint && validateFormat ? hint : ''"
      :persistent-hint="persistentHint"
      @keyup.enter="pressEnter"
      :outlined="isParticipantPortalField"
    >
      <template v-if="isParticipantPortalField" v-slot:append>
        <div @click="toggleView">
          <pc-view-icon v-if="!showPassword" size="20" />
          <pc-view-icon type="off" v-if="showPassword" size="20" />
        </div>
      </template>
    </v-text-field>
    <div
      v-if="isParticipantPortalField && showValidator"
      class="password-validator-style"
    >
      <v-row
        v-if="!isMatchValidator"
        class="password-validator-item-style password-validator-length-requirement"
      >
        <v-col>
          <div class="password-validator-item-group-style">
            <pc-checkmark-icon
              v-if="meetsLengthRequirement"
              icon-color-class="checkmark-color"
              size="16"
            ></pc-checkmark-icon>
            <pc-close-icon
              v-else
              size="16"
              icon-color-class="close-color"
            ></pc-close-icon>
            <span>At least eight characters</span>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="!isMatchValidator"
        class="password-validator-item-style password-validator-number-requirement"
      >
        <v-col>
          <div class="password-validator-item-group-style">
            <pc-checkmark-icon
              v-if="meetsNumberRequirement"
              size="16"
              icon-color-class="checkmark-color"
            ></pc-checkmark-icon>
            <pc-close-icon
              v-else
              size="16"
              icon-color-class="close-color"
            ></pc-close-icon>
            <span>At least one number</span>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="!isMatchValidator"
        class="password-validator-item-style password-validator-number-requirement"
      >
        <v-col>
          <div class="password-validator-item-group-style">
            <pc-checkmark-icon
              v-if="meetsUppercaseRequirement"
              size="16"
              icon-color-class="checkmark-color"
            ></pc-checkmark-icon>
            <pc-close-icon
              v-else
              size="16"
              icon-color-class="close-color"
            ></pc-close-icon>
            <span>At least one upper case letter</span>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="!isMatchValidator"
        class="password-validator-item-style password-validator-number-requirement"
      >
        <v-col>
          <div class="password-validator-item-group-style">
            <pc-checkmark-icon
              v-if="meetsLowercaseRequirement"
              size="16"
              icon-color-class="checkmark-color"
            ></pc-checkmark-icon>
            <pc-close-icon
              v-else
              size="16"
              icon-color-class="close-color"
            ></pc-close-icon>
            <span>At least one lower case letter</span>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="!isMatchValidator"
        class="password-validator-item-style password-validator-number-requirement"
      >
        <v-col>
          <div class="password-validator-item-group-style">
            <pc-checkmark-icon
              v-if="meetsSpecialCharRequirement"
              size="16"
              icon-color-class="checkmark-color"
            ></pc-checkmark-icon>
            <pc-close-icon
              v-else
              size="16"
              icon-color-class="close-color"
            ></pc-close-icon>
            <span>At least one special character (!@#$%^&*)</span>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="isMatchValidator"
        class="password-validator-item-style password-validator-match-requirement"
      >
        <v-col>
          <div class="password-validator-item-group-style">
            <pc-checkmark-icon
              v-if="meetsMatchRequirement"
              size="16"
              icon-color-class="checkmark-color"
            ></pc-checkmark-icon>
            <pc-close-icon
              v-else
              size="16"
              icon-color-class="close-color"
            ></pc-close-icon>
            <span>Passwords match!</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import PcViewIcon from "@/shared/components/design-system/PcViewIcon";
import PcCheckmarkIcon from "@/shared/components/design-system/PcCheckmarkIcon.vue";
import PcCloseIcon from "@/shared/components/design-system/PcCloseIcon";

export default {
  components: {
    PcViewIcon,
    PcCheckmarkIcon,
    PcCloseIcon,
  },
  props: {
    value: {
      type: String,
      default: "",
      required: false,
    },
    label: {
      type: String,
      default: "Password",
    },
    placeholder: {
      type: String,
      default: "Password",
    },
    id: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    validateFormat: {
      type: Boolean,
    },
    hint: {
      type: String,
      default:
        "Minimum 8 characters, one capital letter, one lowercase letter, one number, one special character",
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    isParticipantPortalField: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: "Password",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showValidator: {
      type: Boolean,
      default: false,
    },
    isMatchValidator: {
      type: Boolean,
      default: false,
    },
    passwordMatch: {
      type: String,
      default: "",
    },
    showHint: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      showPassword: false,
      password: this.value,
      passwordRules: [
        (v) =>
          (this.required ? !!v : true) ||
          (!this.isMatchValidator
            ? "Enter a valid " + this.label.toLowerCase()
            : "Passwords do not match"),
        (v) =>
          (this.validateFormat
            ? v
              ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
                  v
                )
              : true
            : true) || "Enter a valid " + this.label.toLowerCase(),
        (v) =>
          (this.isMatchValidator
            ? this.passwordMatch
              ? v == this.passwordMatch
              : true
            : true) || "Passwords do not match",
      ],
    };
  },

  watch: {
    password(newValue) {
      this.$emit("input", newValue);
    },
    value() {
      if (this.value == null) {
        this.password = "";
      } else {
        this.password = this.value;
      }
    },
  },
  computed: {
    meetsLengthRequirement: function () {
      return this.password ? this.password.length >= 8 : false;
    },
    meetsNumberRequirement: function () {
      return this.password ? /\d/.test(this.password) : false;
    },
    meetsUppercaseRequirement: function () {
      return this.password ? /[A-Z]/.test(this.password) : false;
    },
    meetsLowercaseRequirement: function () {
      return this.password ? /[a-z]/.test(this.password) : false;
    },
    meetsSpecialCharRequirement: function () {
      const specialChars = "!@#$%^&*()";
      return this.password
        ? [...this.password].some((char) => specialChars.includes(char))
        : false;
    },
    meetsMatchRequirement: function () {
      return this.passwordMatch ? this.password == this.passwordMatch : false;
    },
  },
  methods: {
    toggleView: function (e) {
      this.showPassword = !this.showPassword;
      e.preventDefault();
    },
    pressEnter: function (e) {
      this.$emit("enter", e);
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss">
.password-text-field.is-participant-portal-field {
  .password-text-field-header-style {
    margin-left: 10px;
    margin-bottom: 8px;
    font-family: "Open Sans", sans-serif;
    color: $neutral70;
    font-size: 12px;
    font-weight: 600;
  }

  div.v-input:not(.error--text, .v-input--is-focused) {
    fieldset {
      border-color: $neutral10 !important;
    }
  }

  div.v-text-field {
    border-radius: 6px !important;
  }

  div.v-text-field__slot {
    margin-top: auto;
    margin-bottom: auto;
    height: 20px;
    font-family: "Open Sans", sans-serif;
    position: relative;
    input {
      color: $neutral70 !important;
      font-size: 14px;
      height: 20px !important;
      padding-right: 25px;
    }

    input::placeholder {
      font-family: "Open Sans", sans-serif !important;
      color: $neutral30 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .v-input__slot {
    min-height: 40px !important;
    height: 20px;
    position: relative;
  }

  .v-input--is-focused {
    .v-input__slot {
      color: $primary !important;
      caret-color: $primary !important;
      box-shadow: 0px 0px 0px 3px $primary10 !important;
      fieldset {
        border-width: 1px !important;
      }
    }
  }

  .v-input__append-inner {
    color: $neutral50 !important;
    margin-top: 10px !important;

    div > svg {
      cursor: pointer;
    }
  }

  .v-text-field__details {
    min-height: 0px;

    div {
      min-height: 0px;
    }

    .error--text {
      margin-top: 2px;
      margin-bottom: 8px;
    }

    .v-messages__wrapper {
      font-size: 10px;
    }
  }

  .password-validator-style {
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    color: $neutral50;
    margin-top: -10px;

    .row {
      padding: 0px;
    }

    .col {
      padding: 0px;
    }

    .password-validator-item-style {
      margin-left: 8px;

      .password-validator-item-group-style {
        display: flex;
        flex-direction: row;
        height: 25px;
        align-items: center;
        line-height: 25px;

        div {
          height: 25px;
          div {
            height: 25px;
            svg {
              margin-top: 4px;
              margin-right: 8px;
            }
          }
        }

        svg.checkmark-color {
          fill: $success60 !important;
        }

        svg.close-color {
          fill: $disabled-text !important;
        }
      }
    }

    .password-validator-length-requirement {
      margin-top: 0px !important;
    }
  }
}
</style>
