const PenChecksColors = {
  install(Vue) {
    Vue.prototype.$colors = {
      // Vue.prototype.$nonGrayColorList
      penChecksGreen: "#98B83C",
      penChecksPromotionalGreen: "#658210",
      penChecksDarkGreen: "#709012",
      penChecksDarkerGreen: "#51680c",
      penChecksOrange: "#E87926",
      penChecksBlue: "#0c5ab4",
      penChecksNavyBlue: "#113B61",
      penChecksBlueGray: "#6C859C",
      penChecksDarkTeal: "#025E76",
      penChecksTeal: "#3D8A9E",
      penChecksLightTeal: "#E7F1F3",
      penChecksRed: "#FF5252",
      penChecksPurple: "#7153d5",
      penChecksPink: "#aa228f",
      penChecksWarn: "#DB7333",
      penChecksLightBlue: "#e4effa",
      penChecksWarnLight: "#F3AF3D",
      penChecksNeutralGray: "#e9edf6",
      penChecksBlueSky: "#0075DB",
      penChecksBluePurple: "#5F6F9C",
      mainSuccess: "#119815",
      // Primaries
      primary: "#0075db", // primary50
      primary00: "#f0f8ff",
      primary10: "#e0f1ff",
      primary20: "#c2e2ff",
      primary30: "#66b8ff",
      primary40: "#1c95fd",
      primary50: "#0075db", // primary
      primary60: "#0764b6",
      primary70: "#0c5697",
      primary80: "#0f487b",
      primary90: "#113b5f",
      // Neutrals
      light: "#f0f3fa", // neutral00
      neutral00: "#f0f3fa", // light
      neutral10: "#e9edf6", // penChecksNeutralGray
      neutral20: "#d4dbed",
      neutral30: "#aeb9d5",
      neutral40: "#8390b3",
      neutral50: "#5f6f9c",
      neutral60: "#465889",
      neutral70: "#30416f",
      neutral80: "#202f5a",
      neutral90: "#101f48", // dark
      dark: "#101f48", // neutral90
      // Accents
      orange: "#e8784c", // orange40
      orange00: "#ffeee0",
      orange10: "#fedbc3",
      orange20: "#f8b691",
      orange30: "#f0996f",
      orange40: "#e8784c", // orange
      orange50: "#d34417",
      orange60: "#b8300f",
      orange70: "#a02108",
      orange80: "#811503",
      orange90: "#660e00",
      green: "#89ae19", // green50
      green00: "#f5fddf",
      green10: "#e3f6ac",
      green20: "#cfec79",
      green30: "#bbe04d",
      green40: "#a2c92c",
      green50: "#89ae19", // green
      green60: "#799c11",
      green70: "#607e07",
      green80: "#526c04",
      green90: "#3d5200",
      // Supports
      success: "#0f8a13", // success60
      success00: "#ebffec",
      success10: "#cdfed3",
      success20: "#84eb8b",
      success30: "#4ed054",
      success40: "#31c438",
      success50: "#19b321",
      success60: "#0f8a13", // success
      success70: "#0a760d",
      success80: "#05610d",
      success90: "#005207",

      warning: "#f3af3d", // warning50
      warning00: "#fff8eb",
      warning10: "#ffedc2",
      warning20: "#ffde85",
      warning30: "#ffc847",
      warning40: "#ffbe3d",
      warning50: "#f3af3d", // warning
      warning60: "#eb9728",
      warning70: "#da791d",
      warning80: "#b45313",
      warning90: "#8e320b",
      error: "#e61e2b", // error50
      error00: "#fff0f0",
      error10: "#fee1e1",
      error20: "#fbadad",
      error30: "#f58484",
      error40: "#ed5e5e",
      error50: "#e61e2b", // error
      error60: "#cb1023",
      error70: "#aa091e",
      error80: "#860319",
      error90: "#660015",
      disabledbackground: "#e4e4e4",
      disabledtext: "#979899",

      nonGrayColorList: [
        "penChecksGreen",
        "penChecksDarkGreen",
        "penChecksDarkerGreen",
        "penChecksOrange",
        "penChecksBlue",
        "penChecksNavyBlue",
        "penChecksBlueGray",
        "penChecksDarkTeal",
        "penChecksTeal",
        "penChecksLightTeal",
        "penChecksRed",
        "penChecksPurple",
        "penChecksPink",
        "penChecksWarn",
        "penChecksLightBlue",
        "penChecksWarnLight",
        "penChecksBlueSky",
        "penChecksBluePurple",
        "mainSuccess",
        "primary",
        "primary00",
        "primary10",
        "primary20",
        "primary30",
        "primary40",
        "primary50",
        "primary60",
        "primary70",
        "primary80",
        "primary90",
        "light",
        "neutral00",
        "neutral10",
        "neutral20",
        "neutral30",
        "neutral40",
        "neutral50",
        "neutral60",
        "neutral70",
        "neutral80",
        "neutral90",
        "dark",
        "orange",
        "orange00",
        "orange10",
        "orange20",
        "orange30",
        "orange40",
        "orange50",
        "orange60",
        "orange70",
        "orange80",
        "orange90",
        "green",
        "green00",
        "green10",
        "green20",
        "green30",
        "green40",
        "green50",
        "green60",
        "green70",
        "green80",
        "green90",
        "success",
        "success00",
        "success10",
        "success20",
        "success30",
        "success40",
        "success50",
        "success60",
        "success70",
        "success80",
        "success90",
        "warning",
        "warning00",
        "warning10",
        "warning20",
        "warning30",
        "warning40",
        "warning50",
        "warning60",
        "warning70",
        "warning80",
        "warning90",
        "error",
        "error00",
        "error10",
        "error20",
        "error30",
        "error40",
        "error50",
        "error60",
        "error70",
        "error80",
        "error90",
      ],

      // Vue.prototype.$grayColorList
      black: "#000000",
      penChecksDark: "#212121",
      penChecksDarkGray: "#616161",
      penChecksMediumGray: "#989898",
      penChecksGray: "#BEBEBE",
      penChecksLightGray: "#E0E0E0",
      penChecksLight: "#F5F5F5",
      penChecksNeutralGray: "#e9edf6",
      penChecksSemiGray: "#f6f7fc",
      white: "#FFFFFF",
      neutral50: "#5F6F9C",
      backgroundLight: "#F6F7FC",

      grayColorList: [
        "black",
        "penChecksDark",
        "penChecksDarkGray",
        "penChecksMediumGray",
        "penChecksGray",
        "penChecksLightGray",
        "penChecksLight",
        "penChecksNeutralGray",
        "penChecksSemiGray",
        "white",
        "neutral50",
        "backgroundLight",
        "disabledbackground",
        "disabledtext",
      ],

      get: function (colorNameOrValue) {
        if (
          Object.prototype.hasOwnProperty.call(
            Vue.prototype.$colors,
            colorNameOrValue
          )
        )
          return Vue.prototype.$colors[colorNameOrValue];

        return Vue.prototype.$colors;
      },
    };

    window.$colors = Vue.prototype.$colors;
  },
};

export default PenChecksColors;
