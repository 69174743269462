"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import $ from "jquery";
import ApiRequest from "@/shared/lib/client-sdk/api-request";
import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";
import Fee from "@/shared/lib/client-sdk/models/fee";
import User from "@/shared/lib/client-sdk/models/user";
import OrderLine from "@/shared/lib/client-sdk/models/order-line";

class Account extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);

    this._isFetchingAssociateableOrderLines = false;
    this._associateableOrderLines = [];
  }

  static getModelClassName() {
    return "Account";
  }

  static getApiPathForSearch() {
    var user = window.authenticatedUser;
    if (user && user.isStaffMember()) {
      return (
        window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/private/accounts"
      );
    } else {
      return (
        window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/accounts"
      );
    }
  }

  getApiPathForCreate() {
    if (this.isSubaccount()) {
      return (
        window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/accounts/" +
        this.masterAccountId +
        "/subaccounts"
      );
    }

    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts"
    );
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.id
    );
  }

  getAdminPath() {
    return "/accounts/" + this.id;
  }

  getClientPath() {
    if (this.isSubaccount()) {
      return "/" + this.masterAccountId + "/account/" + this.id;
    }

    return "/" + this.id + "/account";
  }

  get searchResultTitle() {
    return this.displayName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return [
      this.masterAccountId ? "Subaccount" : "Master Account",
      this.friendlyId.length >= 15
        ? " " +
          this.friendlyId.substr(0, 2) +
          "-" +
          this.friendlyId.substr(2, 6) +
          "-" +
          this.friendlyId.substr(8, 3) +
          "-" +
          this.friendlyId.substr(11)
        : this.friendlyId,
    ];
  }

  get searchResultCreatedAt() {
    return this.createdAt;
  }

  get searchResultStatus() {
    return this.status;
  }

  // If the API returns a relationship as a sub-object, this tells the base model which class to use for them when it laods it in.
  get relationships() {
    return {
      masterAccount: Account,
      accountOwner: User,
    };
  }

  // Returns a new Search Handler ready to search for child accounts
  subaccountSearchHandler() {
    const searchHandler = new SearchRequestHandler("Account");
    searchHandler.resultsPerPage = SearchRequestHandler.allResultsPerPage;
    searchHandler.setRequestUrl(
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/accounts/" +
        this.id +
        "/subaccounts"
    );
    return searchHandler;
  }

  // Returns a new Search Handler ready to search for account plans
  planSearchHandler() {
    const searchHandler = new SearchRequestHandler("Plan");
    searchHandler.setRequestUrl(
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/accounts/" +
        this.id +
        "/plans"
    );
    return searchHandler;
  }

  // Returns a new Search Handler ready to search for account participants
  participantSearchHandler() {
    const searchHandler = new SearchRequestHandler("Participant");
    searchHandler.setRequestUrl(
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/accounts/" +
        this.id +
        "/participants"
    );
    return searchHandler;
  }

  // Returns a new Search Handler ready to search for account held fees
  heldFeeSearchHandler() {
    const searchHandler = new SearchRequestHandler("MasterAccountHeldFee");
    searchHandler.setRequestUrl(
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/accounts/" +
        this.id +
        "/held-fees"
    );
    return searchHandler;
  }

  // Returns a new Search Handler ready to search for account orders
  orderSearchHandler() {
    const searchHandler = new SearchRequestHandler("Order");
    searchHandler.setRequestUrl(
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/accounts/" +
        this.id +
        "/orders"
    );
    return searchHandler;
  }

  // Returns a new Search Handler ready to search for account advisory firms
  advisoryFirmsSearchHandler() {
    const searchHandler = new SearchRequestHandler("Account");
    searchHandler.resultsPerPage = SearchRequestHandler.allResultsPerPage;
    searchHandler.setRequestUrl(
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/accounts/" +
        this.id +
        "/advisory-firms"
    );
    return searchHandler;
  }

  // Returns a new Search Handler ready to search for account campaigns
  campaignSearchHandler() {
    const searchHandler = new SearchRequestHandler("Campaign");
    searchHandler.resultsPerPage = SearchRequestHandler.allResultsPerPage;
    searchHandler.setRequestUrl(
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/accounts/" +
        this.id +
        "/campaigns"
    );
    return searchHandler;
  }

  // Returns a new Search Handler ready to search for account prospects
  prospectSearchHandler() {
    const searchHandler = new SearchRequestHandler("Prospect");
    searchHandler.resultsPerPage = SearchRequestHandler.allResultsPerPage;
    searchHandler.setRequestUrl(
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/accounts/" +
        this.id +
        "/prospects"
    );
    return searchHandler;
  }

  isMasterAccount() {
    return !this.isSubaccount();
  }

  isSubaccount() {
    return (
      (this.masterAccount && this.masterAccount.id) || this.masterAccountId
    );
  }

  get isActive() {
    return this.status === "active";
  }

  isUserAccountOwner(user) {
    if (!user || !this.accountOwner) {
      return false;
    }

    return user.id == this.accountOwner.id;
  }

  refreshFees(callback) {
    if (this.fees && this.fees.length > 0) callback(this.fees);

    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.id +
      "/fees";

    ApiRequest.send("GET", requestUrl, null, Fee).onComplete(
      function (response) {
        if (callback) {
          if (response.successful) {
            this.fees = response.result;
            callback(response.result);
          } else {
            callback(false);
          }
        }
      }.bind(this)
    );
  }

  uploadPlans(file, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/accounts/" +
      this.id +
      "/upload-plans";

    let formData = new FormData();
    formData.append("file", file);

    ApiRequest.upload(requestUrl, formData, Account).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  // isForRecurringOperation is only needed on plan level association
  fetchOrderLinesForAssociation(isForRecurringOperation, callback) {
    if (this._isFetchingAssociateableOrderLines) {
      if ($.isFunction(callback)) callback();

      return;
    }

    this._isFetchingAssociateableOrderLines = true;

    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/private/trust/accounts/" +
      this.id +
      "/order-lines-needing-association";

    ApiRequest.send("GET", requestUrl, null, OrderLine).onComplete(
      function (response) {
        this._isFetchingAssociateableOrderLines = false;

        if (response.successful) {
          this._associateableOrderLines = response.result;

          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  updateLogo(file, callback) {
    var requestUrl = this.getApiPathForRefreshUpdateAndDelete() + "/logo";

    let formData = new FormData();
    formData.append("file", file);

    ApiRequest.upload(requestUrl, formData, Account).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  getLogo(width, height, callback) {
    var queryParams = width || height ? "?" : "";
    queryParams += width ? `width=${width}&` : "";
    queryParams += height ? `height=${height}` : "";

    var requestUrl =
      this.getApiPathForRefreshUpdateAndDelete() + `/logo${queryParams}`;

    console.log(requestUrl);

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  getFileExportNotificationsAccountUser(callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.id +
      "/fileExportNotificationsAccountUser";

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  deleteLogo(callback) {
    var requestUrl = this.getApiPathForRefreshUpdateAndDelete() + "/logo";

    ApiRequest.send("DELETE", requestUrl, this).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  deactivate(callback) {
    var requestUrl = this.getApiPathForRefreshUpdateAndDelete() + "/deactivate";

    var payload = {
      accountId: this.id,
    };

    ApiRequest.send("POST", requestUrl, payload, Account).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  reactivate(callback) {
    var requestUrl = this.getApiPathForRefreshUpdateAndDelete() + "/reactivate";

    var payload = {
      accountId: this.id,
    };

    ApiRequest.send("POST", requestUrl, payload, Account).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  updateFormAdvisor(file, part, callback) {
    var requestUrl =
      this.getApiPathForRefreshUpdateAndDelete() + "/advisorform/" + part;

    let formData = new FormData();
    formData.append("file", file);

    ApiRequest.upload(requestUrl, formData, Account).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  getFormAdvisor(part, callback) {
    var requestUrl =
      this.getApiPathForRefreshUpdateAndDelete() + "/advisorform/" + part;

    ApiRequest.send("GET", requestUrl).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response.message);
        }
      }.bind(this)
    );
  }

  isRegisteredInvestmentAdvisor() {
    var isRia = this.accountTypeCode == "registeredInvestmentAdvisor";

    return isRia;
  }

  isAdvisoryFirm() {
    var isAdvisory = this.accountTypeCode == "advisoryFirm";

    return isAdvisory;
  }

  isAdvisor() {
    var isAdvisor = this.accountTypeCode == "advisor";

    return isAdvisor;
  }

  uploadAdvisorFile(file, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/utils/parse-excel/advisor-excel-file";

    let formData = new FormData();
    formData.append("file", file);

    ApiRequest.upload(requestUrl, formData, Account).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }

  createCampaign(campaignRequest, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.id +
      "/campaigns";

    var requestBody = campaignRequest;

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(function (
      response
    ) {
      if (response.successful) {
        if ($.isFunction(callback)) {
          callback(response.result, null);
        }
      } else {
        if ($.isFunction(callback)) {
          callback(null, response);
        }
      }
    });
  }

  createAdvisorEmail(advisorEmailRequest, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.id +
      "/advisor-emails";

    var requestBody = advisorEmailRequest;

    ApiRequest.send("POST", requestUrl, requestBody).onComplete(function (
      response
    ) {
      if (response.successful) {
        if ($.isFunction(callback)) {
          callback(response.result, null);
        }
      } else {
        if ($.isFunction(callback)) {
          callback(null, response);
        }
      }
    });
  }

  uploadProspectFile(file, callback) {
    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/utils/parse-excel/prospect-excel-file";

    let formData = new FormData();
    formData.append("file", file);

    ApiRequest.upload(requestUrl, formData, Account).onComplete(
      function (response) {
        if (response.successful) {
          if ($.isFunction(callback)) callback(response.result, null);
        } else {
          if ($.isFunction(callback)) callback(null, response);
        }
      }.bind(this)
    );
  }
}

export default Account;
