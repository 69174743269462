<template>
  <div>
    <div v-if="backButtonText || hasSlot('top_right')">
      <pc-button
        class="pb-2 pt-8"
        :id="setId('OutterBackButton')"
        v-show="backButtonText"
        text
        @click="$emit('back')"
        >{{ backButtonText }}</pc-button
      >

      <span class="float-right" v-if="hasSlot('top_right')">
        <slot name="top_right"></slot>
      </span>
    </div>
    <v-card class="vertical-tabs-card" outlined>
      <v-row class="pc-worksheet-container">
        <v-col class="col-11">
          <div style="display: flex; flex-direction: row">
            <div class="default-width" v-if="!isWalkingThroughSections">
              <v-tabs vertical dense v-model="selectedSectionIndex">
                <v-tab id="showAllTab" :key="0" @click="changeSection(0)"
                  >Show All</v-tab
                >
                <v-tab
                  v-for="(section, $index) in sections"
                  :id="section.title.toCamelCase() + 'Tab'"
                  :key="$index + 1"
                  :disabled="section.disabled"
                  @click="changeSection($index + 1)"
                >
                  <pc-icon
                    size="12"
                    v-if="section.hasErrors"
                    name="pc-alert"
                  ></pc-icon>
                  {{ section.title }}
                </v-tab>
              </v-tabs>
            </div>
            <div
              style="flex-grow: 1"
              id="worksheet-sections-container"
              class="pt-8 ml-6"
            >
              <v-row class="pb-1">
                <v-col class="pt-1">
                  <div style="padding-left: 36px; padding-right: 16px">
                    <v-row>
                      <v-col>
                        <span class="title">
                          <template v-if="hasSlot('title')">
                            <div :id="setId('Title')">
                              <slot name="title"></slot>
                            </div>
                          </template>
                          <template v-else>
                            <span :id="setId('Title')">{{ title }}</span>
                          </template>
                        </span>

                        <span
                          class="subtitle"
                          v-if="hasSlot('subtitle') || subtitle"
                        >
                          <template v-if="hasSlot('subtitle')">
                            <slot name="subtitle"></slot>
                          </template>
                          <template v-else>
                            {{ subtitle }}
                          </template>
                        </span>
                        <template v-if="hasSlot('lead')">
                          <div class="d-block">
                            <slot name="lead"></slot>
                          </div>
                        </template>
                      </v-col>
                      <v-col>
                        <span class="top-right-container">
                          <span v-if="hasSlot('worksheet-top-right-content')">
                            <slot name="worksheet-top-right-content"></slot>
                          </span>

                          <span v-if="hasSlot('status')">
                            <slot name="status"></slot>
                          </span>
                          <pc-status-detail
                            v-if="substatus"
                            :status="status"
                            :detail="substatus"
                          ></pc-status-detail>
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>

              <hr size="1" :color="$colors.penChecksLightGray" class="mb-6" />

              <div class="sections-content">
                <div
                  v-show="
                    showWalkthroughComplete && hasSlot('walkthrough-complete')
                  "
                >
                  <slot name="walkthrough-complete"></slot>
                </div>

                <div v-show="!showWalkthroughComplete">
                  <slot></slot>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    idPrefix: {
      type: String,
    },
    status: {
      type: String,
    },
    substatus: {
      type: String,
    },
    backButtonText: {
      type: String,
    },
    noButtons: {
      type: Boolean,
    },
    walkthrough: {
      type: Boolean,
      default: null,
    },
  },

  data: function () {
    return {
      sections: [],
      wasShowingAllBeforeEdit: false,
      scrollYBeforeEdit: null,

      walkthroughStepIndex: null,
      selectedSectionIndex: 0,
    };
  },

  watch: {
    walkthrough: function () {
      this.walkthroughStepIndex = this.walkthrough ? 0 : null;
    },
    walkthroughStepIndex: function () {
      this.selectedSectionIndex =
        this.walkthroughStepIndex !== null
          ? this.walkthroughSectionIndices[this.walkthroughStepIndex]
          : 0;
    },
  },

  computed: {
    isWalkingThroughSections: function () {
      if (this.walkthrough && this.walkthroughStepIndex !== null)
        return (
          this.walkthroughStepIndex < this.walkthroughSectionIndices.length
        );

      return false;
    },
    isOnFirstWalkthroughSection: function () {
      if (this.walkthroughStepIndex !== null)
        return this.walkthroughStepIndex == 0;

      return false;
    },
    isOnLastWalkthroughSection: function () {
      if (this.walkthroughStepIndex !== null)
        return (
          this.walkthroughStepIndex == this.walkthroughSectionIndices.length - 1
        );

      return false;
    },
    showWalkthroughComplete: function () {
      this.refreshChildren();

      if (this.walkthroughStepIndex !== null)
        return (
          this.walkthroughStepIndex >= this.walkthroughSectionIndices.length
        );

      return false;
    },
    walkthroughSectionIndices: function () {
      var indices = [];
      for (var i = 0; i < this.sections.length; i++) {
        var section = this.sections[i];

        if (section.isWalkthroughSection()) indices.push(i + 1);
      }

      return indices;
    },
  },

  methods: {
    hasSlot(name) {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    // Called from child worksheet when a section is added or removed
    refreshChildren() {
      this.sections = this.$childrenWithTag("pc-worksheet-section");
    },

    changeSection(newSectionIndex) {
      this.walkthroughStepIndex = null;
      var currentSelectedSectionIndex = this.selectedSectionIndex;

      // If we are clicking the "Show All" section, move to show all
      if (newSectionIndex == 0) {
        this.selectedSectionIndex = 0;
        return;
      }

      // If we are clicking the same tab do nothing
      if (newSectionIndex == currentSelectedSectionIndex) {
        return;
      }

      var currentSection =
        currentSelectedSectionIndex > 0
          ? this.sections[currentSelectedSectionIndex - 1]
          : null;

      var newSection =
        newSectionIndex > 0 ? this.sections[newSectionIndex - 1] : null;

      // If we clicked on a disabled section, do nothing special
      if (newSection && newSection.disabled) return;

      if (
        currentSection &&
        currentSection.editing &&
        currentSection.isDirty()
      ) {
        this.$modals.confirm(
          {
            title: "Switch sections without saving?",
            text: "Any unsaved changes will be lost.",
            okayButtonText: "Continue",
            cancelButtonText: "Cancel",
          },
          // If we clicked on Continue button
          () => {
            currentSection.cancelButtonClicked();
          },
          // If we clicked on Cancel button
          () => {
            this.$nextTick(() => {
              this.$nextTick(() => {
                this.selectedSectionIndex = currentSelectedSectionIndex;
              });
            });
          }
        );
      } else {
        if (currentSection && currentSection.editing) {
          currentSection.cancelButtonClicked();
        }
      }
    },
    sectionStartedEditing(section) {
      var editingSectionIndex = this.sections.indexOf(section);

      this.scrollYBeforeEdit =
        (window.pageYOffset || document.documentElement.scrollTop) -
        (document.documentElement.clientTop || 0);
      this.wasShowingAllBeforeEdit = this.selectedSectionIndex == 0;

      this.selectedSectionIndex = editingSectionIndex + 1;
    },
    sectionEndedEditing() {
      if (this.wasShowingAllBeforeEdit) {
        this.selectedSectionIndex = 0;

        this.$nextTick(() => {
          window.scrollTo(0, this.scrollYBeforeEdit);
        });
      }
    },

    setId: function (idSufix) {
      return this.idPrefix && idSufix ? this.idPrefix + idSufix : undefined;
    },
  },

  mounted: function () {
    this.walkthroughStepIndex = this.walkthrough ? 0 : null;
  },
};
</script>

<style lang="scss">
.vertical-tabs-card {
  border-radius: 0px;
  padding: 0px 18px;

  .title {
    font-size: 18px !important;
    color: $penChecksTeal;
    font-weight: 400;

    .subtitle {
      color: $penChecksMediumGray !important;
      margin-left: 16px;
    }
  }

  h3 {
    color: $penChecksMediumGray;
  }

  .v-tabs-slider-wrapper {
    left: auto !important;
    right: 0px;
    margin-top: 4px !important;
    width: 2px !important;
    height: 16px !important;
    background-color: $penChecksTeal !important;
    color: $penChecksTeal !important;
    caret-color: $penChecksTeal !important;
  }

  .v-tab {
    text-transform: none !important;
    justify-content: right !important;
    text-align: right !important;
    font-size: 14px !important;
    color: $penChecksTeal;
    padding-left: 0px;
    height: 24px !important;
    padding-right: 12px !important;
    line-height: 24px;
    display: block;
    text-align: right !important;
    letter-spacing: 0px;

    i {
      color: $penChecksRed !important;
      margin-top: -2px;
    }

    &.v-tab--disabled {
      color: $penChecksMediumGray !important;
    }
  }

  .v-tab:not(.v-tab--active) {
    font-weight: 400;
  }

  .default-width {
    width: 190px;
    padding-top: 80px;
  }
}

.top-right-container {
  display: flex;
  justify-content: end;
  align-items: center;
}

@media print {
  #worksheet-sections-container {
    width: 100%;
  }
}
</style>
