<template>
  <v-row class="date-picker py-0 my-o">
    <v-col class="date-picker-col-button-style">
      <v-menu
        v-model="showDatePicker"
        :close-on-content-click="false"
        :close-on-click="false"
        max-width="500"
        allow-overflow
        botton
        :offset-y="true"
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="d-inline-block ml-4 date-picker-label-style"
            v-if="label"
          >
            {{ label ? label : "" }}
          </span>
          <pc-button
            :disabled="disabled"
            outlined
            large
            rounded
            class="date-picker-button-style"
            v-bind="attrs"
            v-on="on"
          >
            <v-row
              v-if="
                !showDatePicker || (showDatePicker && !isEnableEditManually)
              "
            >
              <span class="date-picker-icon-style material-icons">
                calendar_today
              </span>
              <span class="date-picker-text-style ml-2">
                {{ textFieldValue }}</span
              >
              <v-btn
                plain
                @click="clearPressed"
                class="date-picker-clear-date-style"
                v-if="showClearButton"
              >
                <span class="material-icons"> close </span>
              </v-btn>
            </v-row>
            <v-row v-if="showDatePicker && isEnableEditManually">
              <span class="date-picker-edit-icon-style material-icons">
                calendar_today
              </span>
              <v-text-field
                class="date-picker-edit-text-style"
                v-if="showDatePicker"
                :maxlength="formatEditDateValueLength"
                v-model="formattedEditDateValue"
                @keyup="textEditDateKeyUp"
                @click="textEditDateClick($event)"
                :rules="required ? dateRules : []"
                :required="required"
              ></v-text-field>
            </v-row>
          </pc-button>
        </template>
        <div
          class="date-picker calendar-background-color"
          v-click-outside-catch="onClickOutside"
        >
          <v-row
            v-if="showFieldSelectOptions"
            class="calendar-row-select-options"
          >
            <v-col>
              <v-select
                :items="calendarFieldSelectOptions"
                v-model="fieldOptionSelected"
                @change="changeComponentSelectOption"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row v-if="!isYearOption">
            <v-col>
              <v-list dense>
                <v-list-item-group v-model="selectedCalendarOption" mandatory>
                  <v-list-item
                    v-for="(item, index) in calendarItemOptions"
                    :key="index"
                    :disabled="
                      item.disabled || (item.isRange && item.isRange != range)
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col>
              <v-date-picker
                class="date-picker-calendar-style"
                v-model="componentValue"
                :type="monthOnly ? 'month' : 'date'"
                :color="$colors.penChecksLightBlue"
                :headerColor="$colors.penChecksTeal"
                :range="range"
                :min="minDate"
                :max="maxDate"
                :no-title="true"
              ></v-date-picker>
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col>
              <v-row class="date-picker-year-list-style">
                <v-btn-toggle
                  v-model="selectedYear"
                  tile
                  :color="$colors.penChecksTeal"
                  group
                  v-for="item in getListOfYear"
                  :key="item"
                >
                  <v-btn :value="item" class="yearSelect"> {{ item }} </v-btn>
                </v-btn-toggle>
              </v-row>
              <v-row v-if="isYearLegendVisible">
                <span class="date-picker-year-legend-style">{{
                  yearLegend
                }}</span>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import $ from "jquery";
var moment = require("moment");

export default {
  props: {
    value: {}, // in ISO 8601 format: YYYY-MM-DD
    label: {
      type: String,
      default: null,
    },
    range: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
    },
    required: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: "ddd, MMM Do YYYY",
    },
    monthOnly: {
      type: Boolean,
    },
    minDate: {
      type: String, // in ISO 8601 format: YYYY-MM-DD
    },
    maxDate: {
      type: String, // in ISO 8601 format: YYYY-MM-DD
    },
    enforceSameCalendarYearRule: {
      type: Boolean,
      default: false,
    },
    showFieldSelectOptions: {
      type: Boolean,
      default: false,
    },
    calendarFieldSelectOptions: {
      type: Array,
      default: () => [
        { value: "orderCreated", text: "Order created date" },
        { value: "effectiveDate", text: "Payment effective date" },
      ],
    },
    fieldSelectOptionValue: {
      type: String,
      default: "orderCreated",
    },
    dateRangeSeparator: {
      type: String,
      default: "-",
    },
    canEditManually: {
      type: Boolean,
      default: false,
    },
    yearOptions: {
      type: Array,
      default: () => [],
    },
    yearRadioOption: {
      type: String,
      default: "year",
    },
    minYear: {
      type: Number,
      default: 1990,
    },
    yearLegend: {
      type: String,
      default: "",
    },
    allowForwardDate: {
      type: Boolean,
      default: true,
    },
    allowBackwardDate: {
      type: Boolean,
      default: false,
    },
    allTimeTextForRequiredDatePicker: {
      type: String,
      default: "Select time period",
    },
  },

  data: function () {
    return {
      showDatePicker: false,
      componentValue: null,
      lastValidComponentValue: null,
      dateRules: [
        (v) => {
          if (!v) {
            return `Please enter ${this.label}`;
          }

          if (this.enforceSameCalendarYearRule && !this.isSameCalendarYear()) {
            return "Date range must be within a single calendar year.";
          }

          return true;
        },
      ],
      selectedCalendarOption: 0,
      fieldOptionSelected: null,
      setupByEndUser: false,
      formattedEditDateValue: "",
      iso8601Format: "YYYY-MM-DD",
      formatEditDate: "MM/DD/YYYY",
      selectedYear: null,
      wasEditing: false,
    };
  },

  watch: {
    value: function () {
      this.componentValue = this.removeDayIfRequired(this.value);
    },
    componentValue: function () {
      if (!this.range) {
        if (this.componentValue && !$.isArray(this.componentValue)) {
          this.emitDatePickerValue(this.componentValue);
          this.showDatePicker = false;
        }
      } else {
        if (
          this.componentValue &&
          $.isArray(this.componentValue) &&
          this.componentValue.length == 1
        ) {
          this.setupByEndUser = true;
          this.selectedCalendarOption = 0;
        }

        if (
          this.componentValue &&
          $.isArray(this.componentValue) &&
          this.componentValue.length == 2
        ) {
          var isValidAnyDate = this.allowForwardDate && this.allowBackwardDate;
          if (!isValidAnyDate) {
            var isValidDateRange = true;
            var startDate = moment(this.componentValue[0]);
            var endDate = moment(this.componentValue[1]);
            var diff = startDate.diff(endDate);

            if (this.allowForwardDate) {
              if (diff > 0) {
                isValidDateRange = false;
              }
            }

            if (this.allowBackwardDate) {
              if (diff < 0) {
                isValidDateRange = false;
              }
            }

            if (!isValidDateRange) {
              this.componentValue = this.removeDayIfRequired([
                this.componentValue[1],
              ]);
              return;
            }
          }

          this.emitDatePickerValue(this.componentValue);
          this.showDatePicker = false;
        }
      }
    },
    selectedCalendarOption: function () {
      if (this.selectedCalendarOption == -1) return;

      var optionSelected =
        this.calendarItemOptions[this.selectedCalendarOption];

      if (optionSelected.key == "allTime") {
        this.clearByShortOption();
      } else {
        this.setupByEndUser = false;
        switch (optionSelected.key) {
          case "ytd":
            this.setYTDDateRange();
            break;
          case "today":
            this.setTodayDate();
            break;
          case "thisWeek":
            this.setWeekDateRange();
            break;
          case "thisMonth":
            this.setMonthDateRange();
            break;
          case "lastMonth":
            this.setLastMonthRange();
            break;
          case "last6Months":
            this.setLast6MonthDateRange();
            break;
          case "last12Months":
            this.setLast12MonthDateRange();
            break;
          default:
        }
      }
    },
    showDatePicker(value) {
      if (!value) {
        if (this.wasEditing) {
          this.setFormattedEdittedDateTextValue(this.formattedEditDateValue);
        } else {
          this.setFormatEditDateValue(this.componentValue);
        }
      }
    },
    selectedYear(year) {
      this.setYearDateRange(year);
    },
  },

  computed: {
    calendarItemOptions: function () {
      const calendarItemOptions = [
        { key: "allTime", text: "All time", isRange: null },
        { key: "ytd", text: "YTD", isRange: true },
        { key: "today", text: "Today", isRange: false },
        { key: "thisWeek", text: "This week", isRange: true },
        { key: "thisMonth", text: "This month", isRange: true },
        { key: "lastMonth", text: "Last month", isRange: true },
        { key: "last6Months", text: "Last six months", isRange: true },
        { key: "last12Months", text: "Last 12 months", isRange: true },
      ];

      const todayItem = calendarItemOptions.find(
        (item) => item.key === "today"
      );
      if (todayItem) {
        const today = new Date();
        todayItem.disabled = !this.checkDateAvailable(today);
      }
      return calendarItemOptions;
    },
    textFieldValue: function () {
      if (this.selectedCalendarOption == -1) return "";

      if (this.isYearOption) {
        if (this.selectedYear) return this.selectedYear;
        else {
          if (this.required) {
            return this.allTimeTextForRequiredDatePicker;
          } else {
            let optionSelected = this.calendarItemOptions[0];
            return optionSelected.text.toUpperCase();
          }
        }
      }

      if (this.selectedCalendarOption == 0) {
        if (!this.range) {
          if (this.componentValue) {
            return this.$options.filters.formatDate(
              this.componentValue,
              this.format
            );
          } else {
            return this.allTimeTextForRequiredDatePicker;
          }
        }

        if (
          this.componentValue &&
          this.componentValue[0] &&
          this.componentValue[1]
        ) {
          var formattedValue1 = this.$options.filters.formatDate(
            this.componentValue[0],
            this.format
          );
          var formattedValue2 = this.$options.filters.formatDate(
            this.componentValue[1],
            this.format
          );
          return (
            formattedValue1 +
            " " +
            this.dateRangeSeparator +
            " " +
            formattedValue2
          );
        } else {
          if (this.required) {
            return this.allTimeTextForRequiredDatePicker;
          }
        }
      }

      let optionSelected =
        this.calendarItemOptions[this.selectedCalendarOption];
      return optionSelected.text.toUpperCase();
    },
    showClearButton: function () {
      var quickSelection = this.selectedCalendarOption > 0;
      var userSetDate =
        this.selectedCalendarOption == 0 &&
        ((this.range &&
          this.componentValue &&
          this.componentValue.length > 0) ||
          (!this.range && this.componentValue));

      return this.clearable && (quickSelection || userSetDate);
    },
    isYearOption: function () {
      return this.fieldOptionSelected == this.yearRadioOption;
    },
    isEnableEditManually: function () {
      return this.canEditManually && !this.isYearOption;
    },
    getListOfYear: function () {
      var yearList = [];

      if (this.yearOptions.length > 0) {
        for (let i = 0; i < this.yearOptions.length; i++) {
          yearList.push(this.yearOptions[i]);
        }
      } else {
        var currentYear = moment().year();
        var totalYears = currentYear - this.minYear;

        for (let i = currentYear; i >= currentYear - totalYears; i--) {
          yearList.push(i);
        }
      }

      return yearList;
    },
  },

  methods: {
    clearDateValue: function () {
      if (this.range) {
        this.componentValue = [];
        this.emitDatePickerValue(this.componentValue);
      } else {
        this.componentValue = null;
        this.emitDatePickerValue(this.componentValue);
      }
    },
    clearByShortOption: function () {
      if (!this.setupByEndUser) {
        this.clearDateValue();
      }
      this.setupByEndUser = false;
    },
    clearPressed: function () {
      this.selectedCalendarOption = -1;
      this.clearDateValue();

      setTimeout(() => {
        this.selectedCalendarOption = 0;
        this.showDatePicker = false;
      }, 500);
    },
    checkDateAvailable: function (date) {
      const targetDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      const minDate = this.minDate ? new Date(this.minDate) : null;
      const maxDate = this.maxDate ? new Date(this.maxDate) : null;

      if (minDate && targetDate < minDate) return false;
      if (maxDate && targetDate > maxDate) return false;

      return true;
    },
    removeDayIfRequired: function (date) {
      if (!this.monthOnly || !date || $.isArray(date)) return date;

      var pieces = date.split("-");
      if (pieces.length == 3) {
        return pieces[0] + "-" + pieces[1];
      }

      return date;
    },
    isSameCalendarYear: function () {
      if (
        new Date(this.componentValue[0]).getUTCFullYear() !=
        new Date(this.componentValue[1]).getUTCFullYear()
      ) {
        return false;
      }

      return true;
    },
    getStringDate: function (dateToString) {
      var stringDate = dateToString.format(this.iso8601Format);
      return stringDate;
    },
    setTodayDate: function () {
      var today = moment();

      if (this.range) {
        var startDate = this.getStringDate(today);
        var endDate = this.getStringDate(today);
        var selectedDateRange = [startDate, endDate];
        this.componentValue = this.removeDayIfRequired(selectedDateRange);
      } else {
        var todayString = this.getStringDate(today);
        this.componentValue = this.removeDayIfRequired(todayString);
      }
    },
    setYTDDateRange: function () {
      if (this.range) {
        var firstDateOfYear = moment().startOf("year");
        var today = moment();

        var startDate = this.getStringDate(firstDateOfYear);
        var endDate = this.getStringDate(today);
        var dateRange = [startDate, endDate];
        this.componentValue = this.removeDayIfRequired(dateRange);
      } else {
        this.setTodayDate();
      }
    },
    setWeekDateRange: function () {
      if (this.range) {
        var firstDateOfWeek = moment().startOf("week");
        var lastDateOfWeek = moment().endOf("week");

        var startDate = this.getStringDate(firstDateOfWeek);
        var endDate = this.getStringDate(lastDateOfWeek);
        var dateRange = [startDate, endDate];
        this.componentValue = this.removeDayIfRequired(dateRange);
      } else {
        this.setTodayDate();
      }
    },
    setMonthDateRange: function () {
      if (this.range) {
        var firstDateOfMonth = moment().startOf("month");
        var lastDateOfMonth = moment();

        var startDate = this.getStringDate(firstDateOfMonth);
        var endDate = this.getStringDate(lastDateOfMonth);
        var dateRange = [startDate, endDate];
        this.componentValue = this.removeDayIfRequired(dateRange);
      } else {
        this.setTodayDate();
      }
    },
    setLastMonthRange: function () {
      if (this.range) {
        var firstDateOfMonth = moment();
        var lastDateOfMonth = moment();
        firstDateOfMonth.subtract(1, "M");
        lastDateOfMonth.subtract(1, "M");

        firstDateOfMonth.startOf("month");
        lastDateOfMonth.endOf("month");

        var startDate = this.getStringDate(firstDateOfMonth);
        var endDate = this.getStringDate(lastDateOfMonth);
        var dateRange = [startDate, endDate];
        this.componentValue = this.removeDayIfRequired(dateRange);
      } else {
        this.setTodayDate();
      }
    },
    setLastXMonthDateRange: function (months) {
      if (this.range) {
        var firstDateOfMonth = moment();
        var lastDateOfMonth = moment();

        firstDateOfMonth.subtract(months, "M");

        var startDate = this.getStringDate(firstDateOfMonth);
        var endDate = this.getStringDate(lastDateOfMonth);
        var dateRange = [startDate, endDate];
        this.componentValue = this.removeDayIfRequired(dateRange);
      } else {
        this.setTodayDate();
      }
    },
    setLastMonthDateRange: function () {
      this.setLastXMonthDateRange(1);
    },
    setLast6MonthDateRange: function () {
      this.setLastXMonthDateRange(6);
    },
    setLast12MonthDateRange: function () {
      this.setLastXMonthDateRange(12);
    },
    changeComponentSelectOption(newValue) {
      this.fieldOptionSelected = newValue;
      if (this.isYearOption) {
        this.setYearDateRange(this.selectedYear);
      }

      this.$emit("selectOptionChanged", newValue);
    },
    emitDatePickerValue(value) {
      this.wasEditing = false;
      this.lastValidComponentValue = value;
      this.$emit("input", value);
      this.setFormatEditDateValue(value);
    },
    setFormatEditDateValue(value) {
      var formattedValue = "";
      if (!this.range) {
        formattedValue = this.formatEditDate;
        if (value) {
          formattedValue = moment(value, this.iso8601Format).format(
            this.formatEditDate
          );
        }
      } else {
        formattedValue = this.formatEditDate + "-" + this.formatEditDate;
        if (value && value.length == 2) {
          var firstDate = moment(value[0], this.iso8601Format).format(
            this.formatEditDate
          );
          var secondDate = moment(value[1], this.iso8601Format).format(
            this.formatEditDate
          );
          formattedValue = firstDate + "-" + secondDate;
        }
      }

      this.formattedEditDateValue = formattedValue;
    },
    textEditDateKeyUp: function (event) {
      var selectionStart = event.target.selectionStart;
      var selectionEnd = event.target.selectionEnd;
      var value = event.target.value;

      if (!this.range) {
        if (this.formattedEditDateValue == this.formatEditDate) return;
      } else {
        if (
          this.formattedEditDateValue ==
          this.formatEditDate + "-" + this.formatEditDate
        )
          return;
      }

      if (
        this.formattedEditDateValue != null &&
        this.formattedEditDateValue.trim() == ""
      ) {
        this.formattedEditDateValue = null;
        return;
      }

      this.wasEditing = true;

      var isPasting =
        (event.ctrlKey || event.metaKey) && event.key.toLowerCase() == "v";

      if (isPasting) {
        return;
      }

      // Don't format if not at end of string (or pasting)
      if (selectionStart != selectionEnd || selectionStart < value.length) {
        return;
      }

      this.setFormattedEdittedDateTextValue(this.formattedEditDateValue);
    },
    textEditDateClick: function (event) {
      if (this.showDatePicker) {
        event.stopPropagation();
      }
    },
    onClickOutside: function (e) {
      if (this.showDatePicker) {
        this.showDatePicker = false;
      }
    },
    getFormattedEditDateText: function (textBoxValue) {
      if (textBoxValue == null || textBoxValue.trim() == "") return "";

      var numbersOnlyTextValue = textBoxValue.replace(/[^\d]/g, "");

      var monthFirstDate = numbersOnlyTextValue.substr(0, 2);
      var textBoxValueFormatted = monthFirstDate;

      if (numbersOnlyTextValue.length > 2) {
        var dayFirstDate = numbersOnlyTextValue.substr(2, 2);
        textBoxValueFormatted += "/" + dayFirstDate;
      }

      if (numbersOnlyTextValue.length > 4) {
        var yearFirstDate = numbersOnlyTextValue.substr(4, 4);
        textBoxValueFormatted += "/" + yearFirstDate;
      }

      if (this.range) {
        if (numbersOnlyTextValue.length > 8) {
          var monthSecondDate = numbersOnlyTextValue.substr(8, 2);
          textBoxValueFormatted += "-" + monthSecondDate;
        }

        if (numbersOnlyTextValue.length > 10) {
          var daySecondDate = numbersOnlyTextValue.substr(10, 2);
          textBoxValueFormatted += "/" + daySecondDate;
        }

        if (numbersOnlyTextValue.length > 12) {
          var yearSecondDate = numbersOnlyTextValue.substr(12, 4);
          textBoxValueFormatted += "/" + yearSecondDate;
        }
      }

      return textBoxValueFormatted;
    },
    setFormattedEdittedDateTextValue: function (formattedTextBoxValue) {
      var dateFormattedValue = this.getFormattedEditDateText(
        formattedTextBoxValue
      );

      if (dateFormattedValue == "") return;

      this.setComponentValueFromEdittedDateTextValue(dateFormattedValue);
    },
    setComponentValueFromEdittedDateTextValue(value) {
      if (!this.range && value.length == this.formatEditDateValueLength()) {
        var typedDate = moment(value, this.formatEditDate).format(
          this.iso8601Format
        );

        this.setupByEndUser = true;
        this.selectedCalendarOption = 0;
        this.componentValue = this.removeDayIfRequired(typedDate);
      } else if (
        this.range &&
        value.length == this.formatEditDateValueLength()
      ) {
        var firstDate = value.substr(0, 10);
        var secondDate = value.substr(11, 10);

        var dateRange = [
          moment(firstDate, this.formatEditDate).format(this.iso8601Format),
          moment(secondDate, this.formatEditDate).format(this.iso8601Format),
        ];

        this.setupByEndUser = true;
        this.selectedCalendarOption = 0;
        this.componentValue = this.removeDayIfRequired(dateRange);
      }
    },
    formatEditDateValueLength: function () {
      if (this.range) return 21;

      return 10;
    },
    setYearDateRange(year) {
      if (year) {
        var startDate = year + "-01-01";
        var endDate = year + "-12-31";

        this.setupByEndUser = true;
        this.selectedCalendarOption = 0;
        if (!this.range) {
          this.componentValue = this.removeDayIfRequired(startDate);
        } else {
          var dateRange = [startDate, endDate];
          this.componentValue = this.removeDayIfRequired(dateRange);
        }
      } else {
        this.setupByEndUser = false;
        this.clearByShortOption();
      }
    },
    isYearLegendVisible: function () {
      return this.yearLegend.length > 0;
    },
  },

  mounted: function () {
    if (this.range) {
      if (this.value && $.isArray(this.value) && this.value.length == 2) {
        this.componentValue = [
          this.removeDayIfRequired(this.value[0]),
          this.removeDayIfRequired(this.value[1]),
        ];
      } else {
        this.componentValue = [];
      }
    } else {
      if (this.value) {
        this.componentValue = this.removeDayIfRequired(this.value);
      }
    }
    this.setFormatEditDateValue(this.componentValue);

    if (this.calendarFieldSelectOptions.length == 1) {
      this.changeComponentSelectOption(this.calendarFieldSelectOptions[0]);
    } else {
      this.changeComponentSelectOption(this.fieldSelectOptionValue);
    }
  },
};
</script>

<style lang="scss">
.calendar-background-color {
  background-color: $white;
}

.date-picker {
  .calendar-row-select-options {
    padding-left: 10px;
    max-height: 50px;
  }

  .v-list-item--dense,
  .v-list--dense .v-list-item {
    min-height: 30px;
  }

  .date-picker-button-style {
    padding-left: 15px;
    max-width: fit-content;
  }

  span.date-picker-button-style > span > button.v-btn.v-size--default {
    height: 36px !important;
  }

  .date-picker-clear-date-style.v-btn.v-size--default {
    height: 24px !important;
    max-width: 36px !important;
  }

  .date-picker-col-button-style {
    max-width: fit-content;
  }

  .date-picker-label-style {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
    width: 100%;
  }

  .date-picker-text-style {
    margin-top: 4px;
  }

  .date-picker-calendar-style {
    height: 315px;
  }

  .date-picker-calendar-style > div > div > div.v-date-picker-table > table {
    border-collapse: separate;
    border-spacing: 0 5px;
  }

  .date-picker-edit-text-style {
    margin-left: 5px;
  }

  .date-picker-edit-text-style > div div > div.v-text-field__slot > input {
    font-size: 14px !important;
    margin-top: 5px;
    color: $penChecksTeal;
  }

  .date-picker-edit-icon-style {
    height: 24px !important;
    margin-top: 20px;
  }

  .v-date-picker-table--month td {
    padding: 0px 0px;
  }

  .v-date-picker-table--date td {
    padding: 0px 0px;
  }

  .date-picker-year-legend-style {
    margin-left: 25px;
    color: $penChecksMediumGray !important;
    max-width: 90%;
    font-size: x-small;
  }

  .date-picker-year-list-style {
    min-height: 200px;
    max-width: 425px;
    margin-left: 10px;
  }

  .v-date-picker-table--date {
    .v-btn.v-btn--active.accent {
      background-color: $penChecksLightBlue !important;
    }

    .v-btn.v-date-picker-table__current.accent--text {
      background-color: $penChecksLightBlue !important;
      color: $penChecksLightBlue !important;
    }

    .v-btn.v-date-picker-table__current.accent--text
      > div:not(.v-date-picker--first-in-range, .v-date-picker--last-in-range.accent) {
      color: black;
    }

    .v-btn.v-btn--active.v-date-picker-table__current.accent:not(.v-date-picker--first-in-range, .v-date-picker--last-in-range.accent) {
      background-color: white !important;
    }

    td:has(.v-btn.v-btn--active.accent) {
      background-color: $penChecksLightBlue;
    }

    .v-btn.v-btn--active.accent > div {
      color: $black;
    }

    .v-btn.v-date-picker--first-in-range.accent {
      background-color: $penChecksBlue !important;
    }

    td:has(.v-btn.v-date-picker--first-in-range.accent) {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }

    .v-btn.v-date-picker--first-in-range.accent > div {
      color: $white;
    }

    .v-btn.v-date-picker--last-in-range.accent {
      background-color: $penChecksBlue !important;
    }

    td:has(.v-btn.v-date-picker--last-in-range.accent) {
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }

    td:first-child {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }

    td:last-child {
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }

    .v-btn.v-date-picker--last-in-range.accent > div {
      color: $white;
    }
  }
}
</style>
