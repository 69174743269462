<template>
  <v-app id="participant-app">
    <pc-singleton-modals
      id="singleton-participant-modals"
    ></pc-singleton-modals>
    <pc-singleton-overlay
      id="singleton-participant-overlay"
    ></pc-singleton-overlay>
    <pc-singleton-snackbar
      id="singleton-participant-snackbar"
    ></pc-singleton-snackbar>

    <div v-if="initialAuthenticationRequestComplete" class="fill-height">
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
export default {
  components: {},

  data: () => ({
    initialAuthenticationRequestComplete: false,
  }),

  methods: {
    logoutAuthenticatedUser() {
      //  event.preventDefault();
      //   event.returnValue = '';
      // User.logoutAuthenticatedUser(function () {
      //   this.$store.commit('removeAuthenticatedUser')
      //   this.$cache.clear();
      // }.bind(this));
    },
  },

  mounted: function () {
    if (
      window.amplifyRuntimeConfig.get("VUE_APP_ENVIRONMENT") === "production"
    ) {
      var script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      );
      script.setAttribute("type", "text/javascript");
      script.setAttribute("charset", "UTF-8");
      script.setAttribute(
        "data-domain-script",
        "3c8e5960-a032-4706-9cc7-c76f42f7cf15"
      );
      document.head.appendChild(script);
    }

    // This Participant App component is only mounted once per page load, so this is the area to PARTICIPANT site bootstrapping

    // Initalize with the progress overlay
    var options = {
      subtext: "Loading...",
      opacity: 0.001,
      isParticipantPortalField: true,
    };
    this.$overlay.show(options);

    // Refresh authenticated user.
    this.$store.dispatch(
      "refreshAuthenticatedUser",
      function (user) {
        if (user && user.isStaffMember()) {
          document.location = window.amplifyRuntimeConfig.get(
            "VUE_APP_ADMIN_BASE_URL"
          );
        } else if (user && user.isClient()) {
          document.location = window.amplifyRuntimeConfig.get(
            "VUE_APP_CLIENT_BASE_URL"
          );
        } else {
          // all routing for participant user is handled by router
          this.$overlay.hide();
          this.initialAuthenticationRequestComplete = true;
        }
      }.bind(this)
    );

    this.$cache.fetchAndCacheLookupData();

    // set TECH-490
    // window.addEventListener('beforeunload', this.logoutAuthenticatedUser)

    //change fav icon for participant site
    const favicon = document.getElementById("favicon");
    favicon.href =
      window.amplifyRuntimeConfig.get("VUE_APP_PARTICIPANT_BASE_URL") +
      "/participant-favicon.ico";
  },
};
</script>

<style lang="scss">
#participant-app.v-application {
  font-family: "Roboto", sans-serif;

  body,
  p {
    color: $penChecksDarkGray;
  }

  h1 {
    font-size: 22px;
    font-weight: 400;
    color: $penChecksNavyBlue;
  }

  h2 {
    font-size: 18px;
    font-weight: 400;
    color: $penChecksDarkTeal;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    color: $penChecksGreen;
  }

  a {
    color: $penChecksTeal;
    text-decoration: none;
  }

  a.upper {
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    color: $penChecksDarkTeal;
  }

  .text-small {
    font-size: 12px;
    color: $penChecksMediumGray;
  }

  .text-medium {
    font-size: 14px;
    color: $penChecksMediumGray;
  }

  .text-question {
    font-size: 18px;
    font-weight: 500;
    color: $penChecksDarkTeal;
    padding-bottom: 0;
  }

  .text-x-large {
    font-size: 36px;
    font-weight: 500;
    color: $penChecksDarkGray;
  }

  .text-x-semi-large {
    font-size: 24px;
    font-weight: 500;
    color: $penChecksDarkGray;
  }

  .founds-caption {
    font-size: 14px !important;
  }

  .text-currency-header {
    font-size: 20px;
    font-weight: 500;
    color: $penChecksDarkGray;
  }

  .text-currency-header-blue {
    font-size: 20px;
    font-weight: 500;
    color: $penChecksDarkTeal;
  }

  .text-currency-medium {
    font-size: 16px;
    font-weight: 500;
  }

  .text-header-medium {
    font-size: 16px;
    font-weight: 500;
    color: $penChecksDarkGray;
  }

  .text-header-blue-medium {
    font-size: 18px;
    font-weight: 500;
    color: $penChecksDarkTeal;
  }

  .text-header-blue16-medium {
    font-size: 16px;
    font-weight: 500;
    color: $penChecksDarkTeal;
  }

  .text-header-regular {
    font-size: 20px;
    font-weight: 400;
    color: $penChecksDarkGray;
  }

  .text-regular-16 {
    font-size: 16px;
    font-weight: 400;
    color: $penChecksDarkGray;
  }

  .text-regular-14 {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: $penChecksDarkGray;
  }

  .text-regular-12 {
    font-size: 12px;
    font-weight: 400;
    color: $penChecksDarkGray;
  }

  .text-concat {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-home-image {
    font-size: 20px;
    font-weight: 500;
    margin: 24px;
    color: $penChecksOrange;
  }

  // Vuetify v2.4 changed styling for nested grid items, causing spacing across the
  // app to be affected. A solution was found here that restores our previous spacing:
  // https://github.com/vuetifyjs/vuetify/issues/12848
  .row {
    margin-top: 0;
    margin-bottom: 0;
  }

  .row + .row {
    margin-top: 0;
  }
}
</style>
