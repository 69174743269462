"use strict";

import BaseModel from "@/shared/lib/client-sdk/base-model";
import SearchRequestHandler from "@/shared/lib/client-sdk/search/search-request-handler";

class Campaign extends BaseModel {
  constructor(idOrAttributes, onRefreshCallback = null) {
    super(idOrAttributes, onRefreshCallback);
  }

  static getModelClassName() {
    return "Campaign";
  }

  static getApiPathForSearch() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/campaigns"
    );
  }

  getApiPathForCreate(forSingleRecord = false) {
    if (
      !Object.prototype.hasOwnProperty.call(this, "accountId") ||
      this.accountId.length != 32
    )
      console.error("Cannot create a campaign without an account id");

    var requestUrl =
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/accounts/" +
      this.accountId +
      "/campaigns";

    if (forSingleRecord) requestUrl += "/create-one";

    return requestUrl;
  }

  getApiPathForRefreshUpdateAndDelete() {
    return (
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
      "/public/campaigns/" +
      this.id
    );
  }

  getAdminPath() {
    console.error("Cannot go to a campaign.");
  }

  // Returns a new Search Handler ready to search for campign prospects
  prospectSearchHandler() {
    const searchHandler = new SearchRequestHandler("Prospect");
    searchHandler.resultsPerPage = SearchRequestHandler.allResultsPerPage;
    searchHandler.setRequestUrl(
      window.amplifyRuntimeConfig.get("VUE_APP_API_BASE_URL") +
        "/public/campaigns/" +
        this.id +
        "/prospects"
    );
    return searchHandler;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultTitle() {
    return this.displayName;
  }

  // Returns a string, or an array of strings (for a multi-line)
  get searchResultSubtitle() {
    return null;
  }

  get searchResultCreatedAt() {
    return this.createdAt;
  }

  get searchResultStatus() {
    return null;
  }
}

export default Campaign;
