const statusTooltips = {
  install: function (Vue) {
    Vue.prototype.$getStatusTooltip = function (entity, status, substatus) {
      const tooltip = tooltips[entity]?.[status];

      if (
        !entitiesWithTooltips.includes(entity) ||
        status === "accountsDivision"
      ) {
        return "";
      }

      if (substatus && tooltip.substatus?.[substatus]) {
        return tooltip.substatus?.[substatus];
      }

      return tooltip.default;
    };
  },
};

// add "PaymentInstruction" and "Payment" to this array when tooltips for these entities are added
const entitiesWithTooltips = ["Order", "OrderLine"];

const tooltips = {
  Order: {
    open: {
      default: "Order is currently open for edits and review",
      substatus: {
        uploading: "Uploading CSV or Excel File",
        submitting: "Order Submitting",
      },
    },
    waitingForReview: {
      default: "Awaiting Admin user to approve order",
    },
    submitted: {
      default: "Order has been submitted, awaiting assets",
    },
    inProgress: {
      default: "Assets associated order in progress",
    },
    complete: {
      default: "Order has been completed",
    },
    cancelled: {
      default: "Order has been cancelled, cannot be reopened",
    },
  },
  OrderLine: {
    open: {
      default: "Orderline will not begin processing unless submitted",
    },
    submitted: {
      default: "Order line has been submitted, awaiting assets",
    },
    waitingForParticipant: {
      default: "Awaiting participant to make election",
    },
    pending: {
      default: "",
      substatus: {
        waitingForAssets:
          "Order line pending, processing will begin when assets are received",
        depositHold: "Order line pending, current hold on assets",
        thirtyDayWait:
          "Order line is pending, participant has elected to wait 30 days",
      },
    },
    inProgress: {
      default: "",
      substatus: {
        waitingForAssets:
          "Order line pending, processing will begin when assets are received",
        depositHold: "Order line pending, current hold on assets",
        thirtyDayWait:
          "Order line is pending, participant has elected to wait 30 days",
        waitingToPay: "One or more payments",
      },
    },
    complete: {
      default: "",
      substatus: {
        done: "Order line has been marked as complete",
        defaultedToIra:
          "Participant did not respond and was defaulted into an IRA",
        defaultedToTsa:
          "Participant did not respond and was defaulted into a TSA",
        returnedToPlan:
          "Participant did not respond, funds have been returned to plan",
        closed: "Order is complete and has now closed",
      },
    },
    waitingForReissue: {
      default: "Awaiting reissue instructions for order line",
    },
    cancelled: {
      default: "Order has been cancelled",
    },
  },
  // tooltips for paymentInstruction and payment to be added
  // PaymentInstruction: {},
  // Payment: {}
};

export default statusTooltips;
