var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isParticipantPortalField
        ? 'phone-number-text-field is-participant-portal-field'
        : 'phone-number-text-field is-general-field'},[(_vm.model && _vm.extensionAttribute)?_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0"},[(_vm.isParticipantPortalField && _vm.showHeader)?_c('div',{staticClass:"phone-number-text-field-header-style"},[(_vm.required)?_c('span',{staticClass:"error-text"},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(_vm.header)+" ")]):_vm._e(),_c('v-text-field',{attrs:{"rules":_vm.phoneNumberRules,"label":_vm.isParticipantPortalField
                ? ''
                : _vm.label + (_vm.required || _vm.hideOptionalText ? '' : ' (optional)'),"placeholder":_vm.placeholder,"required":_vm.required,"disabled":_vm.disabled,"clearable":_vm.clearable,"dense":_vm.dense,"id":_vm.id,"validate-on-blur":_vm.validateBlur,"color":_vm.$colors.penChecksTeal,"outlined":_vm.isParticipantPortalField},on:{"blur":_vm.blur,"keyup":[_vm.keyup,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enter($event)}]},model:{value:(_vm.formattedPhoneNumber),callback:function ($$v) {_vm.formattedPhoneNumber=$$v},expression:"formattedPhoneNumber"}})],1),_c('v-col',{staticClass:"ma-0 pa-0 ml-3",class:_vm.extensionColClass},[_c('v-text-field',{staticClass:"pa-3",attrs:{"label":_vm.isParticipantPortalField ? '' : 'Ext. (optional)',"placeholder":_vm.isParticipantPortalField ? 'Ext. (optional)' : '',"type":"number","disabled":_vm.disabled,"dense":_vm.dense,"id":_vm.id + 'ext',"color":_vm.$colors.penChecksTeal,"outlined":_vm.isParticipantPortalField},model:{value:(_vm.model[_vm.extensionAttribute]),callback:function ($$v) {_vm.$set(_vm.model, _vm.extensionAttribute, $$v)},expression:"model[extensionAttribute]"}})],1)],1)],1):_c('div',[(_vm.isParticipantPortalField && _vm.showHeader)?_c('div',{staticClass:"phone-number-text-field-header-style"},[(_vm.required)?_c('span',{staticClass:"error-text"},[_vm._v("*")]):_vm._e(),_vm._v(_vm._s(_vm.header)+" ")]):_vm._e(),_c('v-text-field',{attrs:{"rules":_vm.phoneNumberRules,"label":_vm.isParticipantPortalField
            ? ''
            : _vm.label + (_vm.required || _vm.hideOptionalText ? '' : ' (optional)'),"placeholder":_vm.isParticipantPortalField ? _vm.placeholder : '',"required":_vm.required,"disabled":_vm.disabled,"clearable":_vm.clearable,"dense":_vm.dense,"id":_vm.id,"validate-on-blur":_vm.validateBlur,"color":_vm.$colors.penChecksTeal,"outlined":_vm.isParticipantPortalField},on:{"blur":_vm.blur,"keyup":[_vm.keyup,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.enter($event)}]},scopedSlots:_vm._u([(_vm.isParticipantPortalField)?{key:"prepend-inner",fn:function(){return [_c('pc-phone-icon',{staticClass:"phone-number-text-field-icon-style"})]},proxy:true}:null],null,true),model:{value:(_vm.formattedPhoneNumber),callback:function ($$v) {_vm.formattedPhoneNumber=$$v},expression:"formattedPhoneNumber"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }