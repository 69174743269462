import Vue from "vue";
import {
  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  headerCase,
  noCase,
  paramCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase,
} from "change-case";
import { plural, singular } from "pluralize";

Vue.filter("camelCase", function (value) {
  return value ? camelCase(value) : value;
});
Vue.filter("titleCase", function (value) {
  return value ? capitalCase(value) : value;
});
Vue.filter("capitalCase", function (value) {
  return value ? capitalCase(value) : value;
});
Vue.filter("constantCase", function (value) {
  return value ? constantCase(value) : value;
});
Vue.filter("dotCase", function (value) {
  return value ? dotCase(value) : value;
});
Vue.filter("headerCase", function (value) {
  return value ? headerCase(value) : value;
});
Vue.filter("noCase", function (value) {
  return value ? noCase(value) : value;
});
Vue.filter("paramCase", function (value) {
  return value ? paramCase(value) : value;
});
Vue.filter("pascalCase", function (value) {
  return value ? pascalCase(value) : value;
});
Vue.filter("pathCase", function (value) {
  return value ? pathCase(value) : value;
});
Vue.filter("sentenceCase", function (value) {
  return value ? sentenceCase(value) : value;
});
Vue.filter("snakeCase", function (value) {
  return value ? snakeCase(value) : value;
});
Vue.filter("plural", function (value) {
  return value ? plural(value) : value;
});
Vue.filter("singular", function (value) {
  return value ? singular(value) : value;
});
Vue.filter("lowercase", function (value) {
  return value ? value.toLowerCase() : value;
});
Vue.filter("uppercase", function (value) {
  return value ? value.toUpperCase() : value;
});

Vue.filter("initials", function (value, includeSecond = false) {
  const words = value.trim().split(" ");

  var firstWordLetter = "";
  var lastWordLetter = "";

  if (
    words.length > 0 &&
    (words[0].trim().toLowerCase() == "the" ||
      words[0].trim().toLowerCase() == "a" ||
      words[0].trim().toLowerCase() == "an")
  )
    words.shift(); // remove the first word if its "a", "an" or "the"

  if (words.length > 0 && words[0].length > 0)
    firstWordLetter = words[0].substr(0, 1);

  if (includeSecond && words.length > 1 && words[words.length - 1].length > 0)
    lastWordLetter = words[words.length - 1].substr(0, 1);

  return firstWordLetter.toUpperCase() + lastWordLetter.toUpperCase();
});

Vue.filter("friendlyId", function (value) {
  if (!value || value.length == 0 || value.trim() == "") return value;

  return value.toFriendlyId();
});

Vue.filter("socialSecurityNumber", function (value) {
  if (value && (value.length == 9 || value.length == 11)) {
    value = value.replace(/-/g, "").trim();
    return (
      value.substr(0, 3) + "-" + value.substr(3, 2) + "-" + value.substr(5, 4)
    );
  }

  return value;
});

Vue.filter("employerIdentificationNumber", function (value) {
  if (value && value.length == 9) {
    value = value.replace(/-/g, "").trim();
    return value.substr(0, 2) + "-" + value.substr(2, 7);
  }

  return value;
});

Vue.filter("maskedSocialSecurityNumber", function (value) {
  if (value && (value.length == 9 || value.length == 11)) {
    value = value.replace(/-/g, "").trim();

    if (value.startsWith("9")) {
      return "9XX-XX-" + value.substr(5, 4);
    } else {
      return "XXX-XX-" + value.substr(5, 4);
    }
  }

  return value;
});

Vue.filter("phoneNumber", function (value) {
  if (value == null || value.trim() == "") return value;

  var formatted = value;
  if (formatted.startsWith("+1")) formatted = formatted.substr(2);

  var numbersOnly = formatted.replace(/[^\d]/g, "");

  formatted = "(" + numbersOnly.substr(0, 3);

  if (numbersOnly.length > 3) {
    formatted += ") " + numbersOnly.substr(3, 3);
  }

  if (numbersOnly.length > 6) {
    formatted += "-" + numbersOnly.substr(6, 4);
  }

  if (formatted.length == 14) return formatted;

  return value;
});

Vue.filter("highlightQuery", function (value, searchTerms) {
  if (
    value == null ||
    !value.trim ||
    value.trim() == "" ||
    searchTerms == null ||
    !searchTerms.trim ||
    searchTerms.trim() == ""
  )
    return value;

  var regex = searchTerms.replace(/[ ]{2,}/gi, " ").trim(); // get rid of any duplicate spaces

  regex = "(\\b" + regex.replace(/[ ]/gi, "|\\b") + "\\b)";
  regex = new RegExp(regex, "gi");

  var newVal = value.replace(
    regex,
    "<span class='highlight-search-term'>$1</span>"
  );

  return newVal;
});

Vue.filter("enumDisplay", function (value) {
  if (value) {
    const items = window.$cache.get("LookupData_EnumDisplay", []);
    let enumDisplay = items.find((x) => x.enumValue === value);
    let foundDisplay = enumDisplay?.displayName;
    return foundDisplay ? foundDisplay : value.toCapitalCase();
  } else {
    return "";
  }
});

String.camelCase = camelCase;
String.titleCase = capitalCase;
String.capitalCase = capitalCase;
String.constantCase = constantCase;
String.dotCase = dotCase;
String.headerCase = headerCase;
String.noCase = noCase;
String.paramCase = paramCase;
String.pascalCase = pascalCase;
String.pathCase = pathCase;
String.sentenceCase = sentenceCase;
String.snakeCase = snakeCase;
String.plural = plural;
String.singular = singular;

String.prototype.toCamelCase = function () {
  return String.camelCase(this);
};
String.prototype.toTitleCase = function () {
  return String.titleCase(this);
};
String.prototype.toCapitalCase = function () {
  return String.capitalCase(this);
};
String.prototype.toConstantCase = function () {
  return String.constantCase(this);
};
String.prototype.toDotCase = function () {
  return String.dotCase(this);
};
String.prototype.toHeaderCase = function () {
  return String.headerCase(this);
};
String.prototype.toNoCase = function () {
  return String.noCase(this);
};
String.prototype.toParamCase = function () {
  return String.paramCase(this);
};
String.prototype.toPascalCase = function () {
  return String.pascalCase(this);
};
String.prototype.toPathCase = function () {
  return String.pathCase(this);
};
String.prototype.toSentenceCase = function () {
  return String.sentenceCase(this);
};
String.prototype.toSnakeCase = function () {
  return String.snakeCase(this);
};
String.prototype.toPlural = function () {
  return String.plural(this);
};
String.prototype.toSingular = function () {
  return String.singular(this);
};

String.prototype.toFriendlyId = function () {
  var value = this;

  if (!value || value.length == 0 || value.trim() == "") return value;

  if (value.length >= 15) {
    value = value.replace(/-/g, "");
    var returnValue =
      value.substr(0, 2) +
      "-" +
      value.substr(2, 6) +
      "-" +
      value.substr(8, 3) +
      "-" +
      value.substr(11, 4);

    if (value.length > 15) {
      returnValue += "-" + value.substr(15);
    }

    return returnValue;
  }

  return value;
};
